import { useFormBackendHandler, usePersistForm } from '@bas/shared/hooks';
import { ReactHookWizardStep } from '@bas/value-objects';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Slide, Typography } from '@mui/material';
import localForage from 'localforage';
import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  DefaultValues,
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from 'react-hook-form';
import { SwitchTransition } from 'react-transition-group';
import { AnyObjectSchema } from 'yup';
import { Form } from '../Form';

export type ReactHookWizardRenderProps<
  TFieldValues extends FieldValues = FieldValues,
  TReactHookWizardStep extends ReactHookWizardStep<TFieldValues> = ReactHookWizardStep<TFieldValues>
> = {
  children: ReactNode;
  form: UseFormReturn<TFieldValues>;
  isFirstStep: boolean;
  isLastStep: boolean;
  finishedSteps: number[];
  currentStepIndex: number;
  setCurrentStepIndex: (newStepIndex: number) => void;
  currentStep: TReactHookWizardStep;
  handleNext: () => void;
  handlePrev: () => void;
};

export type ReactHookWizardProps<
  TFieldValues extends FieldValues = FieldValues,
  TReactHookWizardStep extends ReactHookWizardStep<TFieldValues> = ReactHookWizardStep<TFieldValues>
> = {
  wizardSteps: TReactHookWizardStep[];
  initialValues: DefaultValues<TFieldValues>;
  onSubmit: SubmitHandler<TFieldValues>;
  initialStepIndex: number;
  children: (
    props: ReactHookWizardRenderProps<TFieldValues, TReactHookWizardStep>
  ) => ReactElement;
  disableFormTag?: boolean;
  useProvider: boolean;
  hideBackendErrors?: boolean;
  persist?: string;
  name: string;
};

export type OverrideSubmitType<TFieldValues extends FieldValues = FieldValues> =

    | ((form: UseFormReturn<TFieldValues>) => SubmitHandler<TFieldValues>)
    | undefined;

export const ReactHookWizard = <
  TFieldValues extends FieldValues = FieldValues,
  TReactHookWizardStep extends ReactHookWizardStep<TFieldValues> = ReactHookWizardStep<TFieldValues>
>({
  wizardSteps,
  initialValues,
  onSubmit,
  initialStepIndex,
  children,
  disableFormTag,
  name,
  useProvider,
  hideBackendErrors,
  persist,
}: ReactHookWizardProps<
  TFieldValues,
  TReactHookWizardStep
>): ReactElement | null => {
  const [loadedData, setLoadedData] = useState(false);
  const [direction, setDirection] = useState<'left' | 'right'>('right');
  const [currentStepIndex, setCurrentStepIndex] =
    useState<number>(initialStepIndex);
  const [finishedSteps, setFinishedSteps] = useState<number[]>([]);
  const [blockNext, setBlockNext] = useState<boolean>(false);
  const [changingStep, setChangingStep] = useState<boolean>(false);
  const changingStepRef = useRef<boolean>(false);
  const [temporarySubmit, overrideSubmit] =
    useState<OverrideSubmitType<TFieldValues>>(undefined);

  const currentStep = useMemo(
    () =>
      wizardSteps[
        currentStepIndex >= wizardSteps.length
          ? currentStepIndex - 1
          : currentStepIndex
      ],
    [wizardSteps, currentStepIndex]
  );

  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex + 1 === wizardSteps.length;

  let resolver;
  if (currentStep && currentStep.validationSchema) {
    let schema: AnyObjectSchema | undefined;
    if (typeof currentStep.validationSchema === 'function') {
      schema = currentStep.validationSchema();
    } else {
      schema = currentStep.validationSchema;
    }

    if (schema) {
      resolver = yupResolver(schema);
    }
  }

  const storedValuesPromise = useMemo(async () => {
    const storage = window.localStorage;
    if (persist) {
      const localForageData = await localForage.getItem(persist);
      if (localForageData && typeof localForageData === 'object') {
        return { ...initialValues, ...localForageData };
      }

      const storedData = storage.getItem(persist);
      if (storedData) {
        return { ...initialValues, ...JSON.parse(storedData) };
      }
    }

    return initialValues;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persist]);

  const form = useForm<TFieldValues>({
    resolver,
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: !persist ? initialValues : undefined,
  });

  useEffect(() => {
    if (persist) {
      (async () => {
        const storedValues = await storedValuesPromise;
        form.reset(storedValues, {
          keepDefaultValues: false,
          keepValues: false,
          keepDirtyValues: false,
        });
        setLoadedData(true);
      })();
    }
  }, [persist, storedValuesPromise, initialValues, form]);

  // eslint-disable-next-line no-console
  console.log(form.formState.errors);

  usePersistForm({ watch: form.watch, persist });

  const handleSubmitWithError = useFormBackendHandler(onSubmit, form);
  const backendErrors = useMemo(
    () => form.formState.errors?.backendErrors,
    [form.formState.errors?.backendErrors]
  );

  const handleSetCurrentStepIndex = useCallback(
    async (newStep: number) => {
      if (
        changingStep ||
        changingStepRef.current ||
        newStep < 0 ||
        form.formState.isSubmitting
      ) {
        return;
      }

      setChangingStep(true);
      changingStepRef.current = true;
      setCurrentStepIndex((value) => {
        if (newStep > value && !form.formState.isValid) {
          setChangingStep(false);
          changingStepRef.current = false;
          return value;
        }

        setDirection(newStep > value ? 'right' : 'left');
        if (form.formState.isValid) {
          setFinishedSteps((val) => [...val.filter((v) => v !== value), value]);
        }

        return newStep;
      });
      setChangingStep(false);
      changingStepRef.current = false;
      form.reset(undefined, {
        keepValues: true,
        keepDefaultValues: true,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      changingStep,
      form.formState.isSubmitting,
      form.formState.isValid,
      form.clearErrors,
      form.reset,
    ]
  );

  const handleNextStep = useCallback(async () => {
    setCurrentStepIndex((value) => {
      setFinishedSteps((val) => [...val.filter((v) => v !== value), value]);

      return value + 1;
    });
    form.reset(undefined, {
      keepValues: true,
      keepDefaultValues: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.reset]);

  const handleNext = useCallback(
    async (disableOverriddenSubmit = false, force = false) => {
      if ((!force && (changingStep || changingStepRef.current)) || blockNext) {
        return;
      }

      setChangingStep(true);
      changingStepRef.current = true;
      let submitFunction: SubmitHandler<TFieldValues> = isLastStep
        ? handleSubmitWithError
        : handleNextStep;

      if (!disableOverriddenSubmit && temporarySubmit) {
        submitFunction = temporarySubmit(form);
      }

      setDirection('right');
      await new Promise((r) => {
        setTimeout(r, 25);
      });

      try {
        await form.handleSubmit(submitFunction)();
      } finally {
        await new Promise((r) => {
          setTimeout(r, 400);
        });
        setChangingStep(false);
        changingStepRef.current = false;
      }
    },
    [
      changingStep,
      blockNext,
      isLastStep,
      handleSubmitWithError,
      handleNextStep,
      temporarySubmit,
      form,
    ]
  );

  const handlePrev = useCallback(async () => {
    if (
      changingStep ||
      changingStepRef.current ||
      isFirstStep ||
      form.formState.isSubmitting
    ) {
      return;
    }

    setChangingStep(true);
    changingStepRef.current = true;
    setDirection('left');
    await new Promise((r) => {
      setTimeout(r, 25);
    });
    setCurrentStepIndex((value) => value - 1);
    await new Promise((r) => {
      setTimeout(r, 25);
    });
    setChangingStep(false);
    changingStepRef.current = false;
    form.clearErrors();
    form.trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changingStep,
    form.formState.isSubmitting,
    form.clearErrors,
    isFirstStep,
  ]);

  if (!currentStep) {
    // eslint-disable-next-line no-console
    console.error('No Step given', currentStepIndex, wizardSteps);
    return null;
  }

  let content = (
    <>
      {children({
        children: (
          <SwitchTransition mode="out-in">
            <Slide
              timeout={175}
              direction={direction}
              key={currentStepIndex}
              onExiting={() =>
                setDirection((val) => (val === 'left' ? 'right' : 'left'))
              }
            >
              <Box className="step">
                {currentStep.render?.({
                  form,
                  actions: {
                    handleNext,
                    handlePrev,
                    setBlockNext,
                    overrideSubmit,
                  },
                })}
              </Box>
            </Slide>
          </SwitchTransition>
        ),
        form,
        isFirstStep,
        isLastStep,
        finishedSteps,
        currentStepIndex,
        setCurrentStepIndex: handleSetCurrentStepIndex,
        currentStep,
        handleNext: () => handleNext(),
        handlePrev: () => handlePrev(),
      })}
      {import.meta.env.MODE !== 'production' && (
        <DevTool control={form.control} />
      )}
    </>
  );

  if (!loadedData && persist) {
    return <span />;
  }

  if (useProvider) {
    content = <FormProvider {...form}>{content}</FormProvider>;
  }

  if (disableFormTag) {
    return content;
  }

  return (
    (<Form
      onSubmit={() => handleNext()}
      name={name}
      isValid={form.formState.isValid && !form.formState.isValidating}
    >
      {backendErrors &&
        backendErrors.message &&
        Array.isArray(backendErrors.message) &&
        !hideBackendErrors &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backendErrors.message.map((message: any, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          (<Typography color="error" key={index}>
            {message}
          </Typography>)
        ))}
      {content}
    </Form>)
  );
};

export default ReactHookWizard;
