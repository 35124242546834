import { isMaterial, Material } from '@bas/planning-domain/models';
import {
  Filterable,
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type MaterialsRequestProps = Pagination & Filterable;

type Response = AxiosResponse<Collection<Material>>;
type QueryKeyType = QueryKey & {
  [0]: 'materials';
  [1]: 'list';
};

function isCurrentQueryKey(object: unknown): object is QueryKeyType {
  return (
    Array.isArray(object) && object[0] === 'materials' && object[1] === 'list'
  );
}

export const MaterialsRequest = async ({
  ...params
}: MaterialsRequestProps): Promise<Response> =>
  axios.get(`api/{tenantId}/materials`, {
    params: { ...params },
  });

export const useMaterialsRequest = (
  request: MaterialsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => MaterialsRequest({ ...request }),
    queryKey: ['materials', 'list', request],
  });

export const MaterialsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isMaterial(data)) {
    queryClient.setQueriesData<Response | undefined>(
      {
        predicate: ({ queryKey }) => {
          if (!isCurrentQueryKey(queryKey)) {
            return false;
          }

          return queryKey[0] === 'materials' && queryKey[1] === 'list';
        },
      },
      (previous) => {
        if (!previous) {
          return previous;
        }

        return {
          ...previous,
          data: {
            ...previous.data,
            member: previous.data.member?.map((material) =>
              material.materialId === data.materialId ? data : material
            ),
          },
        };
      }
    );
  }
};
