import { isVehicle, Vehicle } from '@bas/planning-domain/models';
import {
  Pagination,
  QueryInvalidator,
  QueryOptionsWithKey,
} from '@bas/shared/requests';
import { Collection, ErrorResponse, Uuid } from '@bas/value-objects';
import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';

export type VehiclesByMaterialIdsRequestProps = Pagination & {
  materialIds: Array<Uuid>;
};

type Response = AxiosResponse<Collection<Vehicle>>;
type QueryKeyType = QueryKey & {
  [0]: 'vehicles';
  [1]: 'list';
  [2]: Uuid[];
};

function isCurrentQueryKey(object: unknown): object is QueryKeyType {
  return (
    Array.isArray(object) &&
    object[0] === 'vehicles' &&
    object[1] === 'list' &&
    object[2] &&
    Array.isArray(object[2])
  );
}

export const VehiclesByMaterialIdsRequest = async ({
  materialIds,
  ...params
}: VehiclesByMaterialIdsRequestProps): Promise<Response> =>
  axios.get('api/{tenantId}/vehicles', {
    params: { materialId: materialIds, ...params },
  });

export const useVehiclesByMaterialIdsRequest = (
  request: VehiclesByMaterialIdsRequestProps,
  options: QueryOptionsWithKey<
    Response,
    AxiosError<ErrorResponse>,
    Response,
    QueryKeyType
  > = {}
): UseQueryResult<Response, AxiosError<ErrorResponse>> =>
  useQuery<Response, AxiosError<ErrorResponse>, Response, QueryKeyType>({
    ...options,
    queryFn: async () => VehiclesByMaterialIdsRequest({ ...request }),
    queryKey: ['vehicles', 'list', request.materialIds],
  });

export const VehiclesByMaterialIdsRequestInvalidator: QueryInvalidator = (
  data,
  queryClient
) => {
  if (isVehicle(data)) {
    queryClient.setQueriesData<Response | undefined>(
      {
        predicate: ({ queryKey }) => {
          if (!isCurrentQueryKey(queryKey)) {
            return false;
          }

          return (
            queryKey[0] === 'vehicles' &&
            queryKey[1] === 'list' &&
            queryKey[2].includes(data.materialId)
          );
        },
      },
      (previous) => {
        if (!previous) {
          return previous;
        }

        return {
          ...previous,
          data: {
            ...previous.data,
            member: previous.data.member?.map((material) =>
              material.materialId === data.materialId ? data : material
            ),
          },
        };
      }
    );
  }
};
