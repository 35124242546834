import { HoursOverview } from '@bas/planning-domain/models';
import { useAvailableHoursByRoleAndPeriodRequest } from '@bas/planning-domain/requests';
import { formatHours } from '@bas/shared/utils';
import { colors } from '@bas/theme';
import { Tooltip, TooltipProps } from '@bas/ui/web/atoms';
import { EmployeeProjectRole } from '@bas/value-objects';
import { Grid, Skeleton, styled } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export type DailyCalendarStatisticsTooltipProps = Omit<
  TooltipProps,
  'title'
> & {
  currentDate: Date;
};

const defaultHours = {
  totalPlannableHours: 0,
  totalConfirmedHours: 0,
  totalAvailableHours: 0,
  totalHoursOfDay: 0,
  totalPlannedHours: 0,
  totalReservedHours: 0,
  totalShortageHours: 0,
  realReservedHours: 0,
  realConfirmedHours: 0,
  totalReservedHoursToFullfilled: 0,
  totalConfirmedHoursToFullfilled: 0,
} as HoursOverview;

const DailyCalendarStatisticsTooltip = ({
  open,
  onClose,
  currentDate,
  ...props
}: DailyCalendarStatisticsTooltipProps): ReactElement => {
  const [isHovering, setIsHovering] = useState(false);
  const { data: hourStatisticsData, isLoading } =
    useAvailableHoursByRoleAndPeriodRequest(
      {
        projectRole: EmployeeProjectRole.CO_DRIVER,
        startDate: currentDate,
        endDate: currentDate,
      },
      {
        enabled: open || isHovering,
      }
    );

  const hourStatistics = useMemo(
    () =>
      (hourStatisticsData?.data.member || []).find((h) =>
        dayjs(h.date).isSame(currentDate, 'day')
      ),
    [currentDate, hourStatisticsData?.data]
  );

  const totalEmployeeHours = useMemo(
    () => hourStatistics?.employeeHours || defaultHours,
    [hourStatistics?.employeeHours]
  );

  const totalMovingTruckHours = useMemo(
    () => hourStatistics?.movingTruckHours || defaultHours,
    [hourStatistics?.movingTruckHours]
  );

  const totalMovingLiftHours = useMemo(
    () => hourStatistics?.movingLiftHours || defaultHours,
    [hourStatistics?.movingLiftHours]
  );

  const hourTypes = useMemo(
    () => ({
      employees: totalEmployeeHours,
      movingTrucks: totalMovingTruckHours,
      movingLifts: totalMovingLiftHours,
    }),
    [totalEmployeeHours, totalMovingTruckHours, totalMovingLiftHours]
  );

  const { formatMessage } = useIntl();

  return (
    <Tooltip
      {...props}
      placement="bottom"
      arrow
      allowWider
      open={open}
      onOpen={() => setIsHovering(true)}
      onClose={() => setIsHovering(false)}
      title={
        <Grid container columnSpacing={1}>
          {Object.keys(hourTypes).map((type) => {
            const totalHours = hourTypes[type as keyof typeof hourTypes];
            return (
              <Grid item key={type} xs={4}>
                <Grid container columnSpacing={1}>
                  <Grid item xs={12} fontWeight="bold" pb={2}>
                    <FormattedMessage id={`label.${type}`} />
                  </Grid>
                  <Grid item xs={9}>
                    <FormattedMessage id="label.availableHours" />
                  </Grid>
                  <Grid item xs={3}>
                    {isLoading ? (
                      <Skeleton
                        sx={{
                          backgroundColor: colors.lila[200],
                        }}
                        variant="text"
                        width="100%"
                        height={20}
                      />
                    ) : (
                      `+ ${formatHours(totalHours.totalPlannableHours)}`
                    )}
                  </Grid>
                  {totalHours.totalReservedHours > 0 && (
                    <>
                      <Grid item xs={9} whiteSpace="nowrap">
                        {`${formatMessage({
                          id: 'label.reservedHours',
                        })} ${formatMessage({
                          id: 'label.isPlanned',
                        })}`}
                      </Grid>
                      <Grid item xs={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            Math.max(
                              totalHours.totalReservedHours -
                                totalHours.totalReservedHoursToFullfilled,
                              0
                            )
                          )}`
                        )}
                      </Grid>
                    </>
                  )}
                  {totalHours.totalConfirmedHours > 0 && (
                    <>
                      <Grid item xs={9} whiteSpace="nowrap">
                        {`${formatMessage({
                          id: 'label.confirmedHours',
                        })} ${formatMessage({
                          id: 'label.isPlanned',
                        })}`}
                      </Grid>
                      <Grid item xs={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            Math.max(
                              totalHours.totalConfirmedHours -
                                totalHours.totalConfirmedHoursToFullfilled,
                              0
                            )
                          )}`
                        )}
                      </Grid>
                    </>
                  )}
                  {totalHours.totalReservedHoursToFullfilled > 0 && (
                    <>
                      <Grid item xs={9} whiteSpace="nowrap">
                        {`${formatMessage({
                          id: 'label.reservedHours',
                        })} ${formatMessage({
                          id: 'label.notPlanned',
                        })}`}
                      </Grid>
                      <Grid item xs={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            totalHours.totalReservedHoursToFullfilled
                          )}`
                        )}
                      </Grid>
                    </>
                  )}
                  {totalHours.totalConfirmedHoursToFullfilled > 0 && (
                    <>
                      <Grid item xs={9} whiteSpace="nowrap">
                        {`${formatMessage({
                          id: 'label.confirmedHours',
                        })} ${formatMessage({
                          id: 'label.notPlanned',
                        })}`}
                      </Grid>
                      <Grid item xs={3}>
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `- ${formatHours(
                            totalHours.totalConfirmedHoursToFullfilled
                          )}`
                        )}
                      </Grid>
                    </>
                  )}
                  {totalHours.totalShortageHours < 0 ? (
                    <>
                      <Grid item xs={9} fontWeight="bold">
                        <FormattedMessage id="label.shortageHours" />
                      </Grid>
                      <Grid item xs={3} fontWeight="bold">
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `= ${formatHours(
                            Math.abs(totalHours.totalShortageHours)
                          )}`
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={9} fontWeight="bold">
                        <FormattedMessage id="label.plannableHours" />
                      </Grid>
                      <Grid item xs={3} fontWeight="bold">
                        {isLoading ? (
                          <Skeleton
                            sx={{
                              backgroundColor: colors.lila[200],
                            }}
                            variant="text"
                            width="100%"
                            height={20}
                          />
                        ) : (
                          `= ${formatHours(totalHours.totalAvailableHours)}`
                        )}
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      }
    />
  );
};

const StyledDailyCalendarStatisticsTooltip = styled(
  DailyCalendarStatisticsTooltip
)`
  width: 950px;
  height: 175px;
  border-radius: 10px;
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(3)};
  padding-right: ${({ theme }) => theme.spacing(3)};
  box-sizing: border-box;
`;

export default StyledDailyCalendarStatisticsTooltip;
