import {
  isReeleezeeSettings,
  ReeleezeeSettings,
} from '@bas/integration-domain/models';
import { useReeleezeeMappingOptions } from '@bas/integration-domain/web/hooks';
import { Alert } from '@bas/ui/web/atoms';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccountingIntegrationSettingsBlock } from '../AccountingIntegrationSettingsBlock';

export type ReeleezeeSettingsContentSectionProps = {
  integrationId: Uuid;
  providerSettings: ReeleezeeSettings;
  onEdit: () => void;
};

const ReeleezeeSettingsContentSection = ({
  integrationId,
  providerSettings,
  onEdit,
}: ReeleezeeSettingsContentSectionProps): ReactElement => {
  const {
    administrationsData,
    administrationsError,
    ledgerAccountsError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    taxRatesError,
    vatExternalOptions,
  } = useReeleezeeMappingOptions(
    integrationId,
    providerSettings.administrationId
  );

  const administration = useMemo(() => {
    if (!isReeleezeeSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.administrationId) {
      return undefined;
    }

    return (administrationsData?.data?.member || []).find(
      ({ id }) => id === providerSettings?.administrationId
    );
  }, [providerSettings, administrationsData?.data]);

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        {(administrationsError ||
          ledgerAccountsError ||
          departmentsError ||
          taxRatesError) && (
          <Grid item xs={12} mt={2}>
            <Alert severity="error">
              <FormattedMessage id="settings.integrationDetails.couldNotLoadExternalData" />
            </Alert>
          </Grid>
        )}
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.administration" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isReeleezeeSettings(providerSettings) && administration ? (
              administration.Name
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isReeleezeeSettings(providerSettings) && (
            <AccountingIntegrationSettingsBlock
              providerSettings={providerSettings}
              externalGeneralLedgers={generalLedgerExternalOptions}
              externalCostCenters={costCenterExternalOptions}
              externalVatCodes={vatExternalOptions}
            />
          )}
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default ReeleezeeSettingsContentSection;
