import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
  styled,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { Radio, RadioProps } from '../Radio';

export type RadioGroupProps = MuiRadioGroupProps & {
  options: (RadioProps & { value: string | number | boolean })[];
  label?: string | number | ReactElement;
  disabled?: boolean;
  error?: boolean;
  helperText?: ReactNode | string;
};

const RadioGroupGroup = ({
  options,
  label,
  disabled,
  error,
  helperText,
  ...props
}: RadioGroupProps): ReactElement => (
  <FormControl component="fieldset" error={error} disabled={disabled}>
    {label && <FormLabel component="legend">{label}</FormLabel>}
    <MuiRadioGroup {...props}>
      {options.map((radio, index) => (
        // eslint-disable-next-line react/no-array-index-key
        (<Radio {...radio} key={index} />)
      ))}
      {helperText && (
        <FormHelperText sx={{ width: '100%' }}>{helperText}</FormHelperText>
      )}
    </MuiRadioGroup>
  </FormControl>
);

RadioGroupGroup.defaultProps = {
  row: true,
};

const StyledRadioGroupGroup = styled(RadioGroupGroup)(
  ({ theme }) => `
  .MuiFormControlLabel-root {
    margin-right: ${theme.spacing(4)};
  }
`
);
export default StyledRadioGroupGroup;
