import {
  useTurnoverGroupsRequest,
  useVatCodesRequest,
} from '@bas/financial-domain/requests';
import { GeneralLedgerMappingType } from '@bas/integration-domain/models';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useGeneralLedgerBasOptions = (
  generalLedgerMappingType: GeneralLedgerMappingType
) => {
  const { formatMessage } = useIntl();
  const { data: vatCodesData } = useVatCodesRequest(
    {
      perPage: 99999,
    },
    {
      enabled:
        generalLedgerMappingType === GeneralLedgerMappingType.PER_VAT_CODE,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: identitiesData } = useIdentitiesRequest(
    {},
    {
      enabled:
        generalLedgerMappingType === GeneralLedgerMappingType.PER_IDENTITY,
    }
  );

  const { data: turnoverGroupsData } = useTurnoverGroupsRequest(
    {
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      enabled:
        generalLedgerMappingType ===
        GeneralLedgerMappingType.PER_TURNOVER_GROUP,
    }
  );

  return useMemo((): {
    basId: Uuid;
    label: string;
  }[] => {
    if (generalLedgerMappingType === GeneralLedgerMappingType.PER_VAT_CODE) {
      return (vatCodesData?.data?.member || []).map(
        ({ vatCodeId, country, percentage: { percentage } }) => ({
          basId: vatCodeId,
          label: `${percentage}% - ${country}`,
        })
      );
    }

    if (generalLedgerMappingType === GeneralLedgerMappingType.PER_IDENTITY) {
      return (identitiesData?.data?.member || []).map(
        ({ identityId, companyInformation: { companyName } }) => ({
          basId: identityId,
          label: companyName,
        })
      );
    }

    if (
      generalLedgerMappingType === GeneralLedgerMappingType.PER_TURNOVER_GROUP
    ) {
      return [
        {
          basId: 'none',
          label: formatMessage({ id: 'label.none' }),
        },
        ...(turnoverGroupsData?.data?.member || []).map(
          ({ turnoverGroupId, name }) => ({
            basId: turnoverGroupId,
            label: name,
          })
        ),
      ];
    }

    return [];
  }, [
    formatMessage,
    generalLedgerMappingType,
    identitiesData?.data,
    turnoverGroupsData?.data,
    vatCodesData?.data,
  ]);
};
