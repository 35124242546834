import { SyncStatus } from '@bas/integration-domain/models';
import { Chip, Tooltip } from '@bas/ui/web/atoms';
import { Badge, Box, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export type SyncStatusChipProps = {
  syncStatus: SyncStatus;
  messages: (string | Record<string, string>)[];
  reason?: string;
};

const statusColors: {
  [key: string]:
    | 'primary'
    | 'secondary'
    | 'default'
    | 'error'
    | 'info'
    | 'success'
    | 'warning';
} = {
  [SyncStatus.AWAITING_APPROVAL]: 'warning',
  [SyncStatus.PENDING]: 'info',
  [SyncStatus.IN_PROGRESS]: 'info',
  [SyncStatus.SUCCESS]: 'success',
  [SyncStatus.FAILED]: 'error',
  [SyncStatus.DISAPPROVED]: 'warning',
};

const SyncStatusChip = ({
  syncStatus,
  messages,
  reason,
}: SyncStatusChipProps): ReactElement => {
  const content = (
    <Chip
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {statusColors[syncStatus] && (
            <Badge
              color={statusColors[syncStatus]}
              badgeContent=" "
              variant="dot"
            />
          )}
          <span style={{ paddingLeft: '8px' }}>
            <FormattedMessage id={`syncStatus.${syncStatus}`} />
          </span>
        </Box>
      }
    />
  );

  if (messages.length > 0) {
    return (
      (<Tooltip
        allowWider
        title={
          <Box>
            {messages.map((message, i) => (
              // eslint-disable-next-line react/no-array-index-key
              (<Typography key={i}>
                {typeof message === 'object'
                  ? JSON.stringify(message)
                  : message}
              </Typography>)
            ))}
          </Box>
        }
      >
        <span>{content}</span>
      </Tooltip>)
    );
  }

  if (syncStatus === SyncStatus.DISAPPROVED && reason) {
    return (
      <Tooltip
        title={
          <Box>
            <Typography>{reason}</Typography>
          </Box>
        }
      >
        <span>{content}</span>
      </Tooltip>
    );
  }

  return content;
};

export default SyncStatusChip;
