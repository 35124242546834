import { TurnoverGroup } from '@bas/financial-domain/models';
import { useVatCodesRequest } from '@bas/financial-domain/requests';
import { BillingMomentLineType } from '@bas/shared/models';
import {
  FixedBillingMomentLineInputType,
  FixedBillingMomentLineInputTypeDefaultValues,
  PercentageBillingMomentLineInputType,
} from '@bas/tenant-domain/input-types';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@bas/ui/web/atoms';
import { styled, TableContainer } from '@mui/material';
import { ReactElement } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FixedBillingMomentLineForm } from '../FixedBillingMomentLineForm';
import { PercentageBillingMomentLineForm } from '../PercentageBillingMomentLineForm';

export type BillingMomentLinesFormProps = TableProps & {
  prefix: string;
  turnoverGroups: TurnoverGroup[];
  lineType: BillingMomentLineType;
};

const BillingMomentLinesForm = ({
  prefix,
  turnoverGroups,
  lineType,
  ...args
}: BillingMomentLinesFormProps): ReactElement => {
  const {
    fields: lines,
    append,
    remove,
    swap,
  } = useFieldArray<
    {
      invoiceLines: (
        | FixedBillingMomentLineInputType
        | PercentageBillingMomentLineInputType
      )[];
    }[],
    '0.invoiceLines'
  >({ name: `${prefix}.invoiceLines` as '0.invoiceLines' });

  const { data: vatCodesData } = useVatCodesRequest(
    {
      perPage: 99999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <TableContainer>
      <Table {...args} stickyHeader>
        <TableHead>
          <TableRow>
            {lineType === BillingMomentLineType.PERCENTAGE_AMOUNT && (
              <>
                <TableCell width="62px" />
                <TableCell>
                  <FormattedMessage id="label.description" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="label.percentage" />
                </TableCell>
                <TableCell width="40px" />
              </>
            )}
            {lineType === BillingMomentLineType.FIXED_AMOUNT && (
              <>
                <TableCell width="62px" />
                <TableCell>
                  <FormattedMessage id="label.description" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="label.quantity" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="label.pricePerUnit" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="label.vat" />
                </TableCell>
                <TableCell width="40px" />
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map(({ id }, index) =>
            lineType === BillingMomentLineType.FIXED_AMOUNT ? (
              <FixedBillingMomentLineForm
                key={id}
                prefix={prefix}
                index={index}
                vatCodes={vatCodesData?.data?.member || []}
                remove={remove}
                allowMoveUp={index === 0}
                allowMoveDown={lines.length - 1 === index}
                moveUp={() => swap(index - 1, index)}
                moveDown={() => swap(index + 1, index)}
                turnoverGroups={turnoverGroups}
              />
            ) : (
              <PercentageBillingMomentLineForm
                key={id}
                prefix={prefix}
                index={index}
                remove={remove}
                allowMoveUp={index === 0}
                allowMoveDown={lines.length - 1 === index}
                moveUp={() => swap(index - 1, index)}
                moveDown={() => swap(index + 1, index)}
                turnoverGroups={turnoverGroups}
              />
            )
          )}
          <TableRow>
            <TableCell colSpan={6}>
              <Button
                fullWidth
                variant="outlined"
                onClick={() =>
                  append(FixedBillingMomentLineInputTypeDefaultValues())
                }
              >
                <FormattedMessage id="button.addLine" />
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledBillingMomentLinesForm = styled(BillingMomentLinesForm)(
  () => `
  .MuiTableCell-root {
    padding: 4px 3px;
    border: 0;
    height: auto;
  }

  .MuiFilledInput-root {
    padding-left: 13px;
    padding-right: 13px;
    &, &:hover, &.Mui-focused {
      background: #F4F4F4 !important;
      border-radius: 3px;
      border: 0;

      .MuiFilledInput-input {
        text-align: left;
      }

      .MuiFilledInput-input[inputmode="numeric"] {
        text-align: center;
      }
    }
  }
`
);
export default StyledBillingMomentLinesForm;
