import { colors, fontSizesWeb } from '@bas/theme';
import { styled } from '@mui/material/styles';
import {
  PickersDay as MuiPickersDay,
  PickersDayProps as MuiPickersDayProps,
} from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

export type PickersDayProps<TDate extends Dayjs = Dayjs> =
  MuiPickersDayProps<TDate>;

const PickersDay = <TDate extends Dayjs = Dayjs>({
  ...props
}: PickersDayProps<TDate>) => <MuiPickersDay {...props} />;

PickersDay.defaultProps = {
  disableRipple: true,
};

const StyledPickersDay = styled(PickersDay)(
  ({ theme, outsideCurrentMonth }) => `
  font-size: ${fontSizesWeb.base};
  line-height: 21px;
  color: ${colors.lila[outsideCurrentMonth ? 600 : 800]};
  font-weight: bold;
  height: 42px;
  width: 42px;
  border-radius: initial;
  box-sizing: border-box;

  .MuiCalendarPicker-root {
    padding: ${theme.spacing(2)};
    width: 326px;
  }

  &:hover {
    background: inherit;
    color: ${colors.blue[500]};
  }

  &.Mui-selected, &:focus.Mui-selected {
    background: inherit;
    color: ${colors.blue[500]};
    border: 1px solid ${colors.blue[500]};
  }
`,
);
export default StyledPickersDay;
