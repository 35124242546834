import { useBackofficeSidebarStore } from '@bas/shared/state';
import { colors } from '@bas/theme';
import { Icon } from '@bas/ui/web/base';
import { faChevronsLeft } from '@fortawesome/pro-solid-svg-icons';
import { Box, Grid, IconButton, Slide, styled } from '@mui/material';
import clsx from 'clsx';
import {
  cloneElement,
  isValidElement,
  ReactElement,
  ReactNode,
  Suspense,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SwitchTransition } from 'react-transition-group';
import { ModuleLoadingPage } from '../ModuleLoadingPage';

export type TwoSidesTemplateActions = {
  onCloseRightSide?: () => Promise<void>;
};

export type TwoSidesTemplateProps = {
  className?: string;
  leftSide: ReactNode | undefined | ReactElement;
  rightSide?: ReactNode | ReactElement;
  rightSideId?: string;
  rightSideOpen?: boolean;
  fallbackRightSide?: ReactNode;
  hasTabsInside?: boolean;
  fallbackFullHeight?: boolean;
  inverseWidthOfSides?: boolean;
  leftSideFullWidth?: boolean;
  expendable?: boolean;
};

const TwoSidesTemplate = ({
  className,
  leftSide,
  rightSide,
  rightSideId,
  rightSideOpen,
  fallbackRightSide,
  fallbackFullHeight,
  inverseWidthOfSides,
  hasTabsInside,
  leftSideFullWidth,
  expendable,
}: TwoSidesTemplateProps): ReactElement => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [stillOpen, setStillOpen] = useState<boolean>(false);
  const [closing, setClosing] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const openSidebar = useBackofficeSidebarStore(
    (state) => state.openSidebars[0]?.sidebarType,
  );
  const args = useBackofficeSidebarStore(
    (state) => state.openSidebars[0]?.args,
  );
  const SidebarContent = useBackofficeSidebarStore(
    (state) => state.sidebarContent,
  );

  const onCloseRightSide = useCallback(async () => {
    setClosing(true);
    await new Promise((r) => {
      setTimeout(r, 100);
    });
    setClosing(false);
  }, []);

  const handleToggleExpanded = useCallback(async () => {
    setExpanded((val) => !val);
    await new Promise((r) => {
      setTimeout(r, 300);
    });
    window.dispatchEvent(new Event('resize'));
  }, []);

  const rightSideKey = useMemo(() => {
    if (closing) {
      return 'closing';
    }

    return rightSideId || 'none';
  }, [closing, rightSideId]);

  return (
    <Grid
      container
      className={clsx(className, {
        'Bas-TwoSidesTemplate-InverseWidthOfSides': inverseWidthOfSides,
        'Bas-TwoSidesTemplate-FullHeightFallback': fallbackFullHeight,
        'Bas-TwoSidesTemplate-Open': !!rightSideOpen || stillOpen,
        'Bas-TwoSidesTemplate-HasTabsInside': hasTabsInside,
        'Bas-TwoSidesTemplate-LeftSideFullWidth': leftSideFullWidth,
        'Bas-TwoSidesTemplate-Expanded': expanded,
      })}
    >
      <Grid
        item
        className={clsx(
          'Bas-TwoSidesTemplate-LeftSide',
          { 'Bas-TwoSidesTemplate-Bigger': !inverseWidthOfSides },
          { 'Bas-TwoSidesTemplate-Smaller': inverseWidthOfSides },
        )}
      >
        {isValidElement(leftSide)
          ? cloneElement(
              leftSide as ReactElement<{
                onCloseRightSide?: () => Promise<void>;
              }>,
              { onCloseRightSide },
            )
          : leftSide}
      </Grid>
      <Grid
        item
        className={clsx(
          'Bas-TwoSidesTemplate-RightSide',
          { 'Bas-TwoSidesTemplate-Smaller': !inverseWidthOfSides },
          { 'Bas-TwoSidesTemplate-Bigger': inverseWidthOfSides },
        )}
        ref={containerRef}
      >
        {expendable && (
          <IconButton
            className="Bas-TwoSidesTemplate-ExpandButton"
            disableRipple
            onClick={handleToggleExpanded}
          >
            <Icon
              icon={faChevronsLeft}
              flip={expanded ? 'horizontal' : undefined}
            />
          </IconButton>
        )}
        <Box className="Bas-TwoSidesTemplate-Fallback">{fallbackRightSide}</Box>
      </Grid>
      {(!!rightSideOpen || stillOpen) && (
        <SwitchTransition mode="out-in">
          <Slide
            timeout={175}
            key={rightSideKey}
            direction="left"
            container={containerRef.current}
            onEnter={() => setStillOpen(!!rightSideId)}
          >
            <Box
              className={clsx(
                'Bas-TwoSidesTemplate-Slide',
                { 'Bas-TwoSidesTemplate-Smaller': !inverseWidthOfSides },
                { 'Bas-TwoSidesTemplate-Bigger': inverseWidthOfSides },
              )}
            >
              {isValidElement(rightSide)
                ? cloneElement(
                    rightSide as ReactElement<{
                      onCloseRightSide?: () => Promise<void>;
                    }>,
                    { onCloseRightSide },
                  )
                : rightSide}
            </Box>
          </Slide>
        </SwitchTransition>
      )}
      <SwitchTransition mode="out-in">
        <Slide
          timeout={175}
          key={`${openSidebar}-${JSON.stringify(args)}`}
          direction="left"
          container={containerRef.current}
          hidden={!openSidebar}
        >
          <Box
            className={clsx(
              'Bas-TwoSidesTemplate-OpenSidebar',
              'Bas-TwoSidesTemplate-Slide',
              { 'Bas-TwoSidesTemplate-Smaller': !inverseWidthOfSides },
              { 'Bas-TwoSidesTemplate-Bigger': inverseWidthOfSides },
            )}
          >
            <Suspense fallback={<ModuleLoadingPage />}>
              {SidebarContent && <SidebarContent />}
            </Suspense>
          </Box>
        </Slide>
      </SwitchTransition>
    </Grid>
  );
};

const StyledTwoSidesTemplate = styled(TwoSidesTemplate)(
  ({ theme }) => `
  height: 100%;
  position: relative;

  .Bas-TwoSidesTemplate-LeftSide, .Bas-TwoSidesTemplate-RightSide {
    transition: width 0.3s ease, padding 0.3s ease;
  }

  &.Bas-TwoSidesTemplate-LeftSideFullWidth {
    .Bas-TwoSidesTemplate-LeftSide {
      width: 100%;
    }

    .Bas-TwoSidesTemplate-RightSide {
      width: 0;
    }

    .Bas-TwoSidesTemplate-Fallback {
      display: none;
    }
  }

  .Bas-TwoSidesTemplate-Bigger, .Bas-TwoSidesTemplate-Bigger.Bas-TwoSidesTemplate-Slide {
    width: 56%;
    flex: 1;
  }

  .Bas-TwoSidesTemplate-Smaller, .Bas-TwoSidesTemplate-Smaller.Bas-TwoSidesTemplate-Slide {
    width: 44%;
  }

  .Bas-TwoSidesTemplate-LeftSide {
    padding: ${theme.spacing(6, 6, 0)};
    height: 100%;
    overflow: auto;
    ${theme.breakpoints.down('lg')} {
      padding: ${theme.spacing(6, 5, 0)};
    }
    @media (max-width: 1024px) {
      padding: ${theme.spacing(6, 4, 0)};
    }
  }

  .Bas-TwoSidesTemplate-RightSide {
    height: 100%;
    position: relative;
  }

  .Bas-TwoSidesTemplate-Fallback {
    z-index: 1;
  }

  .Bas-TwoSidesTemplate-Slide {
    z-index: 2;
  }

  .Bas-TwoSidesTemplate-ExpandButton {
    height: 44px;
    width: 44px;
    z-index: 9;
    position: absolute;
    border: 1px solid ${colors.lila[400]};
    border-radius: 44px;
    font-size: 13.41px;
    background: ${theme.palette.common.white};
    top: 29px;
    left: -22px;
    svg {
      transition: transform 0.3s ease;
    }
  }

  &.Bas-TwoSidesTemplate-InverseWidthOfSides {
    .Bas-TwoSidesTemplate-Slide {
      width: 78.6%;
      max-width: 750px;
    }
  }

  .Bas-TwoSidesTemplate-Slide, .Bas-TwoSidesTemplate-Fallback {
    box-sizing: border-box;
    border-left: 1px solid ${colors.lila[400]};
    background: ${theme.palette.common.white};
    position: absolute;
    overflow: auto;
    width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:not(.Bas-TwoSidesTemplate-HasTabsInside) {
   .Bas-TwoSidesTemplate-Slide, .Bas-TwoSidesTemplate-Fallback {
      padding: ${theme.spacing(6, 6, 0)};
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(6, 5, 0)};
      }
      @media (max-width: 1024px) {
        padding: ${theme.spacing(6, 4, 0)};
      }
    }
  }

  &.Bas-TwoSidesTemplate-HasTabsInside {
   .Bas-TwoSidesTemplate-Slide .Bas-RightSideWithTabsTemplate-Content .Bas-RightSideWithTabsTemplate-Tab, .Bas-TwoSidesTemplate-Slide .Bas-RightSideWithTabsTemplate-Header, .Bas-TwoSidesTemplate-Fallback {
      padding: ${theme.spacing(6, 6, 0)};
      ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(6, 5, 0)};
      }
      @media (max-width: 1024px) {
        padding: ${theme.spacing(6, 4, 0)};
      }
    }

    .Bas-TwoSidesTemplate-Slide .Bas-RightSideWithTabsTemplate-Header {
      padding-bottom: 0;
    }

    .Bas-TwoSidesTemplate-Slide .Bas-RightSideWithTabsTemplate-Content .Bas-RightSideWithTabsTemplate-Tab {
      padding-top: ${theme.spacing(3)};
      padding-bottom: ${theme.spacing(3)};
    }

    .Bas-RightSideWithTabsTemplate-Close {
      margin: ${theme.spacing(6, 6, 0)};
      ${theme.breakpoints.down('lg')} {
        margin: ${theme.spacing(6, 5, 0)};
      }
      @media (max-width: 1024px) {
        margin: ${theme.spacing(6, 4, 0)};
      }
    }
  }


  &.Bas-TwoSidesTemplate-Expanded {
    .Bas-TwoSidesTemplate-LeftSide {
      width: 0;
      padding-left: 0;
      padding-right: 0;
    }

    .Bas-TwoSidesTemplate-RightSide {
      width: 100%;
    }
  }
`,
);
export default StyledTwoSidesTemplate;
