import {
  useReeleezeeAdministrationsByIntegrationIdRequest,
  useReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequest,
  useReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequest,
  useReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequest,
} from '@bas/integration-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useReeleezeeMappingOptions = (
  integrationId: Uuid,
  administrationId?: string
) => {
  const { data: administrationsData, isError: administrationsError } =
    useReeleezeeAdministrationsByIntegrationIdRequest({
      integrationId,
    });

  const { data: ledgerAccountsData, isError: ledgerAccountsError } =
    useReeleezeeLedgerAccountsByIntegrationIdAndAdministrationIdRequest(
      {
        integrationId,
        administrationId: administrationId || '',
      },
      { enabled: !!administrationId }
    );

  const generalLedgerExternalOptions = useMemo(
    () =>
      (ledgerAccountsData?.data?.member || []).map(
        ({ id, Description, ExternalGeneralLedgerNumber }) => ({
          externalId: id,
          label: `${Description} (${ExternalGeneralLedgerNumber})`,
        })
      ),
    [ledgerAccountsData?.data]
  );

  const { data: departmentsData, isError: departmentsError } =
    useReeleezeeDepartmentsByIntegrationIdAndAdministrationIdRequest(
      {
        integrationId,
        administrationId: administrationId || '',
      },
      { enabled: !!administrationId }
    );

  const costCenterExternalOptions = useMemo(
    () =>
      (departmentsData?.data?.member || []).map(
        ({ id, DepartmentDescription, ExternalCode }) => ({
          externalId: id,
          label: `${DepartmentDescription} (${ExternalCode})`,
        })
      ),
    [departmentsData?.data]
  );

  const { data: taxRatesData, isError: taxRatesError } =
    useReeleezeeTaxRatesByIntegrationIdAndAdministrationIdRequest(
      {
        integrationId,
        administrationId: administrationId || '',
      },
      { enabled: !!administrationId }
    );

  const vatExternalOptions = useMemo(
    () =>
      (taxRatesData?.data?.member || []).map(({ id, Description }) => ({
        externalId: id,
        label: Description,
      })),
    [taxRatesData?.data]
  );

  return {
    administrationsData,
    administrationsError,
    ledgerAccountsError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    taxRatesError,
    vatExternalOptions,
  };
};
