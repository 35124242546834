import {
  Integration,
  isScanMoversSettings,
} from '@bas/integration-domain/models';
import { usePackagesRequest } from '@bas/project-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import {
  ContentSection,
  ContentSectionCancelAction,
  ContentSectionEditAction,
  ContentSectionSaveAction,
  IdentityIndicator,
} from '@bas/ui/web/molecules';
import { Grid, Skeleton, Typography } from '@mui/material';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type ScanMoversSettingsContentSectionProps = {
  integration: Integration;
  onEdit: () => void;
  onCancel?: () => void;
  children?: ReactNode;
};

const ScanMoversSettingsContentSection = ({
  integration: { providerSettings },
  onEdit,
  onCancel,
  children,
}: ScanMoversSettingsContentSectionProps): ReactElement => {
  const { data: packagesData, isPending: isLoadingPackages } =
    usePackagesRequest({ perPage: 999 });
  const tenantState = useTenantStore((state) => state.internalTenant);

  const useIdentities = useMemo(
    () => tenantState?.useIdentities,
    [tenantState]
  );

  if (!isScanMoversSettings(providerSettings)) {
    return (
      <ContentSection
        editing={!!onCancel}
        actions={
          onCancel
            ? [
                <ContentSectionSaveAction />,
                <ContentSectionCancelAction onClick={onCancel} />,
              ]
            : [<ContentSectionEditAction onClick={onEdit} />]
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.settings" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormattedMessage id="label.notSetUpYet" />
          </Grid>
        </Grid>
      </ContentSection>
    );
  }

  return (
    <ContentSection
      editing={!!onCancel}
      actions={
        onCancel
          ? [
              <ContentSectionSaveAction />,
              <ContentSectionCancelAction onClick={onCancel} />,
            ]
          : [<ContentSectionEditAction onClick={onEdit} />]
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        <Grid container spacing={1}>
          {useIdentities && providerSettings.identityId && (
            <>
              <Grid item xs={5}>
                <Typography>
                  <FormattedMessage id="label.identityId" />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <IdentityIndicator
                    identityId={providerSettings.identityId}
                    full
                  />
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={5}>
            <Typography>
              <FormattedMessage id="label.packageId" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              {isLoadingPackages && <Skeleton variant="text" width="100" />}
              {
                packagesData?.data?.member.find(
                  ({ packageId }) => packageId === providerSettings.packageId
                )?.packageName
              }
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography>
              <FormattedMessage id="label.pricingType" />
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography>
              <FormattedMessage
                id={`pricingTypes.${providerSettings.pricingType}`}
              />
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default ScanMoversSettingsContentSection;
