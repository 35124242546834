import {
  ResendEmailInputType,
  ResendEmailInputTypeValidationBuilder,
} from '@bas/communication-domain/input-types';
import { Email, EmailAttachment } from '@bas/communication-domain/models';
import { EmailAttachmentByEmailIdAndContentIdRequest } from '@bas/communication-domain/requests';
import { colors } from '@bas/theme';
import { Button } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import { ContentSection, Dialog, DialogProps } from '@bas/ui/web/molecules';
import { FormDialog } from '@bas/ui/web/organisms';
import { faDownload } from '@fortawesome/pro-light-svg-icons';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Skeleton,
  styled,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { decode } from 'js-base64';
import { ReactElement, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ResendEmailForm } from '../ResendEmailForm';

export type ShowEmailDialogProps = DialogProps & {
  email?: Email;
  isLoading: boolean;
  onResend: (values: ResendEmailInputType) => void | Promise<void>;
};

const ShowEmailDialog = ({
  email,
  isLoading,
  onClose,
  onResend,
  ...args
}: ShowEmailDialogProps): ReactElement => {
  const [isResendingEmail, setIsResendingEmail] = useState<boolean>(false);
  const forceFullScreen = useMediaQuery('@media (max-height:768px)');

  const onDownloadAttachment = useCallback(
    async (attachment: EmailAttachment) => {
      if (email) {
        const response = await EmailAttachmentByEmailIdAndContentIdRequest({
          emailId: email.emailId,
          attachmentId: attachment.attachmentId,
        });

        if (response.status === 200 && !!response.data) {
          const url = window.URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', attachment.fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    [email]
  );

  return (
    (<Dialog
      fullWidth
      fullScreen={forceFullScreen}
      maxWidth="md"
      onClose={onClose}
      {...args}
    >
      <DialogTitle>
        {isLoading && <Skeleton variant="text" />}
        {!isLoading && email?.subject}
      </DialogTitle>
      <DialogContent>
        <ContentSection actions={[]}>
          <Box className="Bas-ShowEmailDialog-Container">
            <Box className="Bas-ShowEmailDialog-Details">
              <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">
                    <FormattedMessage id="label.details" />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography>
                    <FormattedMessage id="label.from" />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Typography>
                    {isLoading && <Skeleton variant="text" />}
                    {!isLoading &&
                      `${email?.sender.name} <${email?.sender.emailAddress}>`}
                  </Typography>
                </Grid>
                {(isLoading || !!email?.toReceivers.length) && (
                  <Grid item xs={6} md={4}>
                    <Typography>
                      <FormattedMessage id="label.to" />
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={6} md={8}>
                  <Typography>
                    {isLoading && <Skeleton variant="text" />}
                    {!isLoading &&
                      email?.toReceivers
                        .map(
                          ({ emailAddress, name }) =>
                            `${name} <${emailAddress}>`
                        )
                        .join(', ')}
                  </Typography>
                </Grid>
                {!!email?.ccReceivers.length && (
                  <>
                    <Grid item xs={6} md={4}>
                      <Typography>
                        <FormattedMessage id="label.cc" />
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <Typography>
                        {email?.ccReceivers
                          .map(
                            ({ emailAddress, name }) =>
                              `${name} <${emailAddress}>`
                          )
                          .join(', ')}
                      </Typography>
                    </Grid>
                  </>
                )}
                {!!email?.bccReceivers.length && (
                  <>
                    <Grid item xs={6} md={4}>
                      <Typography>
                        <FormattedMessage id="label.cc" />
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={8}>
                      <Typography>
                        {email?.bccReceivers
                          .map(
                            ({ emailAddress, name }) =>
                              `${name} <${emailAddress}>`
                          )
                          .join(', ')}
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Box className="Bas-ShowEmailDialog-Content">
              {isLoading && (
                <Skeleton variant="rectangular" height="60vh" width="100%" />
              )}
              {!isLoading && email?.emailContent.htmlContent && (
                <iframe
                  className="Bas-ShowEmailDialog-iFrame"
                  title="email"
                  srcDoc={decode(email?.emailContent.htmlContent)}
                  sandbox=""
                />
              )}
              {!isLoading &&
                !email?.emailContent.htmlContent &&
                !!email?.emailContent.textContent &&
                decode(email?.emailContent.textContent)}
            </Box>
            <Box className="Bas-ShowEmailDialog-Attachments">
              <Grid container columnSpacing={3}>
                {email?.attachments.map((attachment, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  (<Grid item key={index}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      className="Bas-ShowEmailDialog-DownloadButton"
                      onClick={() => onDownloadAttachment(attachment)}
                      fullWidth
                    >
                      <Icon icon={faDownload} />
                      {attachment.name}
                    </Button>
                  </Grid>)
                ))}
              </Grid>
            </Box>
          </Box>
        </ContentSection>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsResendingEmail(true)} variant="outlined">
          <FormattedMessage id="button.resend" />
        </Button>
        <Button onClick={() => !!onClose && onClose({}, 'escapeKeyDown')}>
          <FormattedMessage id="button.close" />
        </Button>
      </DialogActions>
      {isResendingEmail && (
        <FormDialog
          open={isResendingEmail}
          name="resend-email"
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onSubmit={async (values: any) => {
            await onResend(values);
            setIsResendingEmail(false);
          }}
          title={
            <FormattedMessage
              id="showEmails.resendEmail.title"
              values={{ subject: email?.subject }}
            />
          }
          dialogActions={[
            <Button
              key="cancel"
              variant="outlined"
              onClick={() => setIsResendingEmail(false)}
            >
              <FormattedMessage id="button.cancel" />
            </Button>,
            <Button type="submit" key="save">
              <FormattedMessage id="button.save" />
            </Button>,
          ]}
          useProvider
          validationSchema={ResendEmailInputTypeValidationBuilder()}
          defaultValues={{
            receivers: email?.toReceivers.map(
              ({ emailAddress }) => emailAddress
            ),
          }}
          onClose={() => setIsResendingEmail(false)}
        >
          <ResendEmailForm />
        </FormDialog>
      )}
    </Dialog>)
  );
};

const StyledShowEmailDialog = styled(ShowEmailDialog)(
  ({ theme }) => `
  .Bas-ShowEmailDialog-Content {
    height: 60vh;
  }

  .Bas-ShowEmailDialog-iFrame {
    width: 100%;
    height: 100%;
    border: none;
  }

  @media (max-height:768px) {
    .Bas-ShowEmailDialog-Content {
      height: 100%;
    }
  }

  .Bas-ShowEmailDialog-Container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .Bas-ShowEmailDialog-Content, .Bas-ShowEmailDialog-Attachments {
      margin-top: ${theme.spacing(2)};
    }
  }


  .Bas-ShowEmailDialog-DownloadButton {
    padding: ${theme.spacing(1, 2)};
    &:not(.Mui-disabled) {
      border: 1px solid ${colors.lila[800]} !important;
    }

    svg {
      margin-right: 11px;
    }
  }
`
);
export default StyledShowEmailDialog;
