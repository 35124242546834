import { useNmbrsCompaniesByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  ReactHookFormDatePickerField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid, MenuItem } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type NmbrsSettingsFormProps = {
  integrationId: Uuid;
};

const NmbrsSettingsForm = ({
  integrationId,
}: NmbrsSettingsFormProps): ReactElement => {
  const { data: companiesData } = useNmbrsCompaniesByIntegrationIdRequest({
    integrationId,
  });

  const companies = useMemo(
    () => companiesData?.data?.member || [],
    [companiesData]
  );

  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={6}>
        <Controller
          name="startDate"
          render={(registered) => (
            <ReactHookFormDatePickerField
              {...registered}
              textFieldProps={{
                fullWidth: true,
              }}
              label={<FormattedMessage id="label.startDate" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="companyId"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.company" />}
              select
            >
              {companies.map((company) => (
                <MenuItem key={company.companyId} value={company.companyId}>
                  {company.name}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default NmbrsSettingsForm;
