import { colors, fontSizesWeb } from '@bas/theme';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  styled,
} from '@mui/material';
import { forwardRef, ReactElement, ReactNode } from 'react';

export type ButtonProps = MuiButtonProps & {
  target?: string;
  download?: string | ReactNode;
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }: ButtonProps, ref): ReactElement => (
    <MuiButton {...props} ref={ref} />
  ),
);

Button.defaultProps = {
  variant: 'contained',
  disableFocusRipple: true,
  disableRipple: true,
};

const StyledButton = styled(Button)(
  ({ theme }) => `
  text-transform: none;
  font-size: 16px;
  line-height: 21px;
  box-shadow: none;
  border-radius: 5px;
  font-weight: 700;

  padding: 11px 18px;
  &.MuiButton-sizeSmall {
    padding: 9.5px 11px;
  }

  &.MuiButton-contained {
    border: 1px solid transparent;
  }

  &:disabled {
    &.MuiButton-contained {
      color: #fff;
    }

    &.MuiButton-outlined {
      border-color: ${colors.lila[400]};
    }

  }

  &.MuiLoadingButton-loading {
    color: transparent !important;
    .MuiLoadingButton-loadingIndicator {
      color: ${colors.blue[800]};
    }
  }

  &.MuiButton-outlinedPrimary {
    border: 1px solid ${theme.palette.primary.main};
  }

  &.MuiButton-outlinedSecondary:not(.Mui-disabled) {
    color: ${colors.lila[700]};
    border: 1px solid ${colors.lila[700]};
    &:hover {
      background: inherit;
    }
  }

  &.MuiButton-text {
    border-radius: 0;
    &:hover {
      background: none;
    }

    &:not(:focus) {
      border: 1px solid transparent;
    }

    & > svg {
      font-size: ${fontSizesWeb.base};
      margin-right: 7px;
    }

    &.MuiButton-sizeSmall {
      font-weight: normal;
      font-size: ${fontSizesWeb.sm};
      line-height: 18px;
    }
  }

  &.MuiButton-textSecondary:not(.Mui-disabled) {
    color: ${colors.lila[800]};

    &:hover {
      color: ${colors.blue[500]};
    }
  }

  &:focus {
    border: 1px solid ${colors.yellow[700]};
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }

  &:active {
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.3);
    &.MuiButton-containedPrimary {
      background-color: ${theme.palette.primary.main};
    }
    &.MuiButton-containedSecondary {
      background-color: ${theme.palette.secondary.main};
    }
  }
`,
);

const FinalButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ ...props }: ButtonProps, ref): ReactElement => (
    <StyledButton {...props} ref={ref} />
  ),
);

export default FinalButton;
