import { UserByUserIdRequest } from '@bas/authentication-domain/requests';
import { EmployeeByEmployeeIdRequest } from '@bas/hrm-domain/requests';
import {
  useAuthStore,
  useEmployeeStore,
  useTenantStore,
  useUserStore,
} from '@bas/shared/state';
import { useEffect } from 'react';

export const useRetrieveGlobalStates = () => {
  const isAuthenticated = useAuthStore(state => state.isAuthenticated);
  const userId = useAuthStore(state => state.userId);
  const employeeId = useAuthStore(state => state.employeeId);

  const tenantId = useTenantStore(state => state?.internalTenant?.tenantId || state?.tenant?.tenantId);
  const userStateUserId = useUserStore(state => state.user?.userId);
  const setUserState = useUserStore(state => state.setUser);
  const employeeStateEmployeeId = useEmployeeStore(state => state.employee?.employeeId);
  const setEmployee = useEmployeeStore(state => state.setEmployee);

  useEffect(() => {
    (async () => {
      if (isAuthenticated && !!userId && userStateUserId !== userId) {
        try {
          const { data } = await UserByUserIdRequest({
            userId,
          });

          setUserState(data);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
  }, [isAuthenticated, userId, userStateUserId, setUserState]);

  useEffect(() => {
    (async () => {
      if (
        isAuthenticated &&
        !!employeeId &&
        !!tenantId &&
        employeeStateEmployeeId !== employeeId
      ) {
        try {
          const { data } = await EmployeeByEmployeeIdRequest({
            employeeId,
          });

          setEmployee(data);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      }
    })();
  }, [
    tenantId,
    employeeId,
    employeeStateEmployeeId,
    setEmployee,
    isAuthenticated,
  ]);
};
