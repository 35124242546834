import { ListedEmployee } from '@bas/hrm-domain/models';
import {
  EmployeesRequestProps,
  useEmployeesRequest,
} from '@bas/hrm-domain/requests';
import {
  ReactHookFormAutocomplete,
  ReactHookFormAutocompleteProps,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

type ReactHookFormEmployeeSelectProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<
  ReactHookFormAutocompleteProps<ListedEmployee, TFieldValues, TName>,
  'options'
> & {
  requestOptions?: Partial<EmployeesRequestProps>;
  preferredEmployeeIds?: Uuid[];
  preferredEmployeesLabel?: string;
};

const ReactHookFormEmployeeSelect = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  requestOptions,
  preferredEmployeeIds,
  preferredEmployeesLabel,
  ...props
}: ReactHookFormEmployeeSelectProps<TFieldValues, TName>) => {
  const { data: employeesData, isPending: isLoadingEmployees } =
    useEmployeesRequest({
      ...requestOptions,
    });

  const employees = useMemo(
    () => employeesData?.data?.member || [],
    [employeesData?.data]
  );

  const handleRenderEmployeeOptionLabel = useCallback(
    (option: ListedEmployee | Uuid) =>
      typeof option === 'string'
        ? employees.find((c) => c.employeeId === option)?.personName.fullName ||
          ''
        : option.personName.fullName,
    [employees]
  );

  const { formatMessage } = useIntl();
  const handleGroupBy = useCallback(
    (option: ListedEmployee) => {
      if (preferredEmployeeIds?.includes(option.employeeId)) {
        return (
          preferredEmployeesLabel || formatMessage({ id: 'label.preferred' })
        );
      }

      return formatMessage({ id: 'label.otherEmployees' });
    },
    [formatMessage, preferredEmployeeIds, preferredEmployeesLabel]
  );

  const sortedEmployees = useMemo(() => {
    if (!preferredEmployeeIds) {
      return employees;
    }

    const preferredEmployees = employees.filter((employee) =>
      preferredEmployeeIds?.includes(employee.employeeId)
    );

    const otherEmployees = employees.filter(
      (employee) => !preferredEmployeeIds?.includes(employee.employeeId)
    );

    return [...preferredEmployees, ...otherEmployees];
  }, [employees, preferredEmployeeIds]);

  const usePreferredEmployees = useMemo(
    () => !!preferredEmployeeIds && preferredEmployeeIds.length > 0,
    [preferredEmployeeIds]
  );

  return (
    <ReactHookFormAutocomplete<ListedEmployee, TFieldValues, TName>
      textField={{
        fullWidth: true,
        label: <FormattedMessage id="label.employee" />,
      }}
      identifier="employeeId"
      options={usePreferredEmployees ? sortedEmployees : employees}
      groupBy={usePreferredEmployees ? handleGroupBy : undefined}
      getOptionLabel={handleRenderEmployeeOptionLabel}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      disabled={isLoadingEmployees || props.disabled}
      loading={isLoadingEmployees}
      {...props}
    />
  );
};

export default ReactHookFormEmployeeSelect;
