import {
  useOctopusAccountsByIntegrationIdAndDossierIdRequest,
  useOctopusCostCentersByIntegrationIdAndDossierIdRequest,
  useOctopusDossiersByIntegrationIdRequest,
  useOctopusJournalsByIntegrationIdAndDossierIdRequest,
  useOctopusVatCodesByIntegrationIdAndDossierIdRequest,
} from '@bas/integration-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useOctopusMappingOptions = (
  integrationId: Uuid,
  dossierId?: string
) => {
  const { data: dossiersData, isError: dossiersError } =
    useOctopusDossiersByIntegrationIdRequest({
      integrationId,
    });

  const { data: generalLedgersData, isError: generalLedgersError } =
    useOctopusAccountsByIntegrationIdAndDossierIdRequest(
      {
        integrationId,
        dossierId: dossierId || '',
      },
      { enabled: !!dossierId }
    );

  const generalLedgerExternalOptions = useMemo(
    () =>
      (generalLedgersData?.data?.member || []).map(
        ({ accountKey, description }) => ({
          externalId: accountKey,
          label: `${description} (${accountKey})`,
        })
      ),
    [generalLedgersData?.data]
  );

  const { data: costCentersData, isError: costCentersError } =
    useOctopusCostCentersByIntegrationIdAndDossierIdRequest(
      {
        integrationId,
        dossierId: dossierId || '',
      },
      { enabled: !!dossierId }
    );

  const costCenterExternalOptions = useMemo(
    () =>
      (costCentersData?.data?.member || []).map(
        ({ costCentreKey, description }) => ({
          externalId: costCentreKey,
          label: description,
        })
      ),
    [costCentersData?.data]
  );

  const { data: vatCodesData, isError: vatCodesError } =
    useOctopusVatCodesByIntegrationIdAndDossierIdRequest(
      {
        integrationId,
        dossierId: dossierId || '',
      },
      { enabled: !!dossierId }
    );

  const vatExternalOptions = useMemo(
    () =>
      (vatCodesData?.data?.member || []).map(({ description, code }) => ({
        externalId: code,
        label: `${description} (${code})`,
      })),
    [vatCodesData?.data]
  );

  const { data: journalsData, isError: journalsError } =
    useOctopusJournalsByIntegrationIdAndDossierIdRequest(
      {
        integrationId,
        dossierId: dossierId || '',
      },
      { enabled: !!dossierId }
    );

  const journalsExternalOptions = useMemo(
    () =>
      (journalsData?.data?.member || []).map((journal) => ({
        externalId: journal.journalKey,
        label: journal.name,
      })),
    [journalsData?.data]
  );

  return {
    dossiersData,
    dossiersError,
    generalLedgersError,
    generalLedgerExternalOptions,
    costCentersError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsError,
    journalsExternalOptions,
  };
};
