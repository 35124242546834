import {
  useExactOnlineCostCentersByIntegrationIdAndDivisionCodeRequest,
  useExactOnlineDivisionsByIntegrationIdRequest,
  useExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequest,
  useExactOnlineJournalsByIntegrationIdAndDivisionCodeRequest,
  useExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequest,
} from '@bas/integration-domain/requests';
import { Uuid } from '@bas/value-objects';
import { useMemo } from 'react';

export const useExactOnlineMappingOptions = (
  integrationId: Uuid,
  divisionCode?: string
) => {
  const { data: divisionsData, isError: divisionsError } =
    useExactOnlineDivisionsByIntegrationIdRequest({
      integrationId,
    });

  const { data: generalLedgersData, isError: generalLedgersError } =
    useExactOnlineGLAccountsByIntegrationIdAndDivisionCodeRequest(
      {
        integrationId,
        divisionCode: divisionCode || '',
      },
      { enabled: !!divisionCode }
    );

  const generalLedgerExternalOptions = useMemo(
    () =>
      (generalLedgersData?.data?.member || []).map(({ description, code }) => ({
        externalId: code,
        label: `${description} (${code})`,
      })),
    [generalLedgersData?.data]
  );

  const { data: costCentersData, isError: departmentsError } =
    useExactOnlineCostCentersByIntegrationIdAndDivisionCodeRequest(
      {
        integrationId,
        divisionCode: divisionCode || '',
      },
      { enabled: !!divisionCode }
    );

  const costCenterExternalOptions = useMemo(
    () =>
      (costCentersData?.data?.member || []).map(
        ({ id, description, code }) => ({
          externalId: id,
          label: `${description} (${code})`,
        })
      ),
    [costCentersData?.data]
  );

  const { data: vatCodesData, isError: vatCodesError } =
    useExactOnlineVatCodesByIntegrationIdAndDivisionCodeRequest(
      {
        integrationId,
        divisionCode: divisionCode || '',
      },
      { enabled: !!divisionCode }
    );

  const vatExternalOptions = useMemo(
    () =>
      (vatCodesData?.data?.member || []).map(({ description, code }) => ({
        externalId: code,
        label: `${description} (${code})`,
      })),
    [vatCodesData?.data]
  );

  const { data: journalsData, isError: journalsError } =
    useExactOnlineJournalsByIntegrationIdAndDivisionCodeRequest(
      {
        integrationId,
        divisionCode: divisionCode || '',
      },
      { enabled: !!divisionCode }
    );

  const journalsExternalOptions = useMemo(
    () =>
      (journalsData?.data?.member || []).map((journal) => ({
        externalId: journal['@code'],
        label: journal.description,
      })),
    [journalsData?.data]
  );

  return {
    divisionsData,
    divisionsError,
    generalLedgersError,
    generalLedgerExternalOptions,
    departmentsError,
    costCenterExternalOptions,
    vatCodesError,
    vatExternalOptions,
    journalsError,
    journalsExternalOptions,
  };
};
