import { Integration, isNmbrsSettings } from '@bas/integration-domain/models';
import { useNmbrsCompaniesByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';

export type NmbrsSettingsContentSectionProps = {
  integration: Integration;
  onEdit: () => void;
};

const NmbrsSettingsContentSection = ({
  integration: { integrationId, providerSettings },
  onEdit,
}: NmbrsSettingsContentSectionProps): ReactElement => {
  const { data: companiesData } = useNmbrsCompaniesByIntegrationIdRequest({
    integrationId,
  });

  const company = useMemo(() => {
    if (!isNmbrsSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.companyId) {
      return undefined;
    }

    return (companiesData?.data?.member || []).find(
      ({ companyId }) => companyId === providerSettings?.companyId
    );
  }, [providerSettings, companiesData?.data]);

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.company" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isNmbrsSettings(providerSettings) && company ? (
              company.name
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.startDate" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isNmbrsSettings(providerSettings) &&
              providerSettings.startDate && (
                <FormattedDate
                  value={providerSettings.startDate}
                  dateStyle="short"
                />
              )}
          </Typography>
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default NmbrsSettingsContentSection;
