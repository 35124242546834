import { VatCode } from '@bas/financial-domain/models';
import { useTurnoverGroupsRequest } from '@bas/financial-domain/requests';
import {
  EstimationItemToUseForQuote,
  isEstimationItemToUseForQuote,
  QuoteMomentLineType,
} from '@bas/shared/models';
import { colors } from '@bas/theme';
import {
  CurrencyTextFieldNumberFormat,
  TableCell,
  TableRow,
  TableRowProps,
  TextFieldNumberFormat,
  Tooltip,
} from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import {
  ReactHookFormAutocomplete,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import {
  InternalServiceType,
  ProjectEstimateItemGroupType,
  ProjectEstimateItemType,
  ProjectType,
} from '@bas/value-objects';
import {
  faArrowDown,
  faArrowUp,
  faClock,
  faSquareCheck,
  faTrashCan,
} from '@fortawesome/pro-light-svg-icons';
import {
  faClock as faSolidClock,
  faSquareCheck as faSolidSquareCheck,
} from '@fortawesome/pro-solid-svg-icons';
import { Box, Grid, MenuItem, styled } from '@mui/material';
import clsx from 'clsx';
import { ReactElement, useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

export type QuoteMomentLineFormProps = TableRowProps & {
  index: number;
  vatCodes: VatCode[];
  remove: (index: number) => void;
  allowMoveUp: boolean;
  allowMoveDown: boolean;
  moveUp: () => void;
  moveDown: () => void;
  projectType?: ProjectType;
};

const itemsToUseOptions: EstimationItemToUseForQuote[] = [
  {
    groupType: ProjectEstimateItemGroupType.PROJECT,
    itemType: ProjectEstimateItemType.HOURS,
    serviceTypes: [],
    allowedProjectTypes: [
      ProjectType.TRANSPORT_JOB,
      ProjectType.MOVING_LIFT_JOB,
    ],
  },
  {
    groupType: ProjectEstimateItemGroupType.PROJECT,
    itemType: ProjectEstimateItemType.TRAVEL_TIME,
    serviceTypes: [],
    allowedProjectTypes: [
      ProjectType.TRANSPORT_JOB,
      ProjectType.MOVING_LIFT_JOB,
    ],
  },
  {
    groupType: ProjectEstimateItemGroupType.PROJECT,
    itemType: ProjectEstimateItemType.DISTANCE,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.TRANSPORT_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.PROJECT,
    itemType: ProjectEstimateItemType.BUILDING_TYPE,
    serviceTypes: [],
    allowedProjectTypes: [
      ProjectType.TRANSPORT_JOB,
      ProjectType.MOVING_LIFT_JOB,
    ],
  },

  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.CUBIC_METER,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.STAFF,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.HOURS,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.BOXES,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.BUILDING_TYPE,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.TRAVEL_TIME,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.DISTANCE,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [InternalServiceType.MOVING_LIFT],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [InternalServiceType.PROVIDE_BOXES],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.HANDYMAN,
    itemType: ProjectEstimateItemType.HOURS,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.HANDYMAN,
    itemType: ProjectEstimateItemType.STAFF,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.PACKING,
    itemType: ProjectEstimateItemType.HOURS,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.PACKING,
    itemType: ProjectEstimateItemType.STAFF,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.UNPACKING,
    itemType: ProjectEstimateItemType.HOURS,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.UNPACKING,
    itemType: ProjectEstimateItemType.STAFF,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [InternalServiceType.CERTIFICATE],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [InternalServiceType.INSURANCE],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [InternalServiceType.PERMITS],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.MOVING_JOB,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [InternalServiceType.WASTE_DISPOSAL],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.STORAGE,
    itemType: ProjectEstimateItemType.SERVICE,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
  {
    groupType: ProjectEstimateItemGroupType.STORAGE,
    itemType: ProjectEstimateItemType.SURCHARGE,
    serviceTypes: [],
    allowedProjectTypes: [ProjectType.MOVING_JOB],
  },
];

const QuoteMomentLineForm = ({
  index,
  vatCodes,
  remove,
  className,
  allowMoveUp,
  allowMoveDown,
  moveUp,
  moveDown,
  projectType,
  ...args
}: QuoteMomentLineFormProps): ReactElement => {
  const lineType = useWatch({ name: `quote.quoteLines.${index}.lineType` });
  const hourlyLine = useWatch({ name: `quote.quoteLines.${index}.hourlyLine` });
  const optional = useWatch({ name: `quote.quoteLines.${index}.optional` });

  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();

  const filteredItemsToUseOptions = useMemo(
    () =>
      itemsToUseOptions.filter(
        ({ allowedProjectTypes }) =>
          !projectType ||
          !allowedProjectTypes ||
          allowedProjectTypes.length === 0 ||
          allowedProjectTypes.includes(projectType)
      ),
    [projectType]
  );

  const { data: turnoverGroupsData } = useTurnoverGroupsRequest(
    {
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const getOptionLabel = useCallback(
    (option: EstimationItemToUseForQuote): string => {
      if (option.serviceTypes.length === 1) {
        return formatMessage({
          id: `quoteMoment.groupTypes.${option.groupType}.itemTypes.${option.itemType}.${option.serviceTypes[0]}`,
        });
      }
      return formatMessage({
        id: `quoteMoment.groupTypes.${option.groupType}.itemTypes.${option.itemType}`,
      });
    },
    [formatMessage]
  );

  const turnoverGroups = turnoverGroupsData?.data?.member || [];

  const actions = (
    <Grid container spacing={1}>
      <Grid
        item
        sx={{
          cursor: 'pointer',
          color: (theme) => (hourlyLine ? colors.blue[500] : 'inherit'),
        }}
      >
        <Tooltip
          title={<FormattedMessage id="label.hourlyRate.explained" />}
          placement="top"
        >
          <Box>
            <Icon
              icon={hourlyLine ? faSolidClock : faClock}
              onClick={() =>
                setValue(`quote.quoteLines.${index}.hourlyLine`, !hourlyLine)
              }
              fontSize={20}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item>
        <Tooltip
          title={<FormattedMessage id="label.optional.explained" />}
          placement="top"
        >
          <Box>
            <Icon
              icon={optional ? faSolidSquareCheck : faSquareCheck}
              onClick={() =>
                setValue(`quote.quoteLines.${index}.optional`, !optional)
              }
              fontSize={20}
              sx={{
                cursor: 'pointer',
                color: (theme) => (optional ? colors.blue[500] : 'inherit'),
              }}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Grid item sx={{ cursor: 'pointer' }}>
        <Icon icon={faTrashCan} onClick={() => remove(index)} fontSize={20} />
      </Grid>
    </Grid>
  );

  const moveActions = (
    <Grid container spacing={1}>
      <Grid
        item
        sx={{
          color: (theme) => (allowMoveUp ? colors.lila[400] : 'inherit'),
        }}
      >
        <Icon
          icon={faArrowUp}
          onClick={allowMoveUp ? undefined : moveUp}
          fontSize={20}
        />
      </Grid>
      <Grid
        item
        sx={{
          color: (theme) => (allowMoveDown ? colors.lila[400] : 'inherit'),
        }}
      >
        <Icon
          icon={faArrowDown}
          onClick={allowMoveDown ? undefined : moveDown}
          fontSize={20}
        />
      </Grid>
    </Grid>
  );

  if (lineType === QuoteMomentLineType.CALCULATED_QUOTE_MOMENT_LINE) {
    return (
      <>
        <TableRow
          {...args}
          className={clsx(className, 'Bas-QuoteMomentLineForm-FirstRow')}
        >
          <TableCell>{moveActions}</TableCell>
          <TableCell colSpan={3}>
            <Controller
              name={`quote.quoteLines.${index}.description`}
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  multiline
                  variant="filled"
                />
              )}
            />
          </TableCell>
          <TableCell width="104px">
            <Controller
              name={`quote.quoteLines.${index}.vatCodeId`}
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  variant="filled"
                  select
                >
                  {vatCodes
                    .filter((vatCode) => !!vatCode.percentage)
                    .map((vatCode) => (
                      <MenuItem
                        key={vatCode.vatCodeId}
                        value={vatCode.vatCodeId}
                      >
                        {`${vatCode.percentage?.percentage}%`}
                      </MenuItem>
                    ))}
                </ReactHookFormTextField>
              )}
            />
          </TableCell>
          <TableCell width="77px">{actions}</TableCell>
        </TableRow>
        <TableRow className={className}>
          <TableCell colSpan={1} />
          <TableCell colSpan={4}>
            <Grid container spacing="6px" alignItems="stretch">
              <Grid item xs={12} lg={6}>
                <Controller
                  name={`quote.quoteLines.${index}.itemsToUseForPrice`}
                  render={(registered) => (
                    <ReactHookFormAutocomplete
                      {...registered}
                      fullWidth
                      multiple
                      textField={{
                        variant: 'filled',
                        className: 'Bas-Select-WithLabel',
                        InputLabelProps: {
                          shrink: true,
                        },
                        label: (
                          <FormattedMessage id="label.itemsToUseForPrice" />
                        ),
                      }}
                      options={filteredItemsToUseOptions}
                      getOptionLabel={getOptionLabel}
                      disableCloseOnSelect
                      isOptionEqualToValue={(
                        option: unknown | EstimationItemToUseForQuote,
                        value: unknown | EstimationItemToUseForQuote
                      ) => {
                        if (
                          !isEstimationItemToUseForQuote(option) ||
                          !isEstimationItemToUseForQuote(value)
                        ) {
                          return false;
                        }

                        return (
                          option.groupType === value.groupType &&
                          option.itemType === value.itemType &&
                          isEqual(option.serviceTypes, value.serviceTypes)
                        );
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Controller
                  name={`quote.quoteLines.${index}.itemsToUseForQuantity`}
                  render={(registered) => (
                    <ReactHookFormAutocomplete
                      {...registered}
                      fullWidth
                      multiple
                      textField={{
                        variant: 'filled',
                        className: 'Bas-Select-WithLabel',
                        InputLabelProps: {
                          shrink: true,
                        },
                        label: (
                          <FormattedMessage id="label.itemsToUseForQuantity" />
                        ),
                      }}
                      options={filteredItemsToUseOptions}
                      getOptionLabel={getOptionLabel}
                      disableCloseOnSelect
                      isOptionEqualToValue={(
                        option: unknown | EstimationItemToUseForQuote,
                        value: unknown | EstimationItemToUseForQuote
                      ) => {
                        if (
                          !isEstimationItemToUseForQuote(option) ||
                          !isEstimationItemToUseForQuote(value)
                        ) {
                          return false;
                        }

                        return (
                          option.groupType === value.groupType &&
                          option.itemType === value.itemType &&
                          isEqual(option.serviceTypes, value.serviceTypes)
                        );
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
        <TableRow
          className={clsx(className, 'Bas-QuoteMomentLineForm-LastRow')}
        >
          <TableCell colSpan={2} />
          <TableCell colSpan={3}>
            <Controller
              name={`quote.quoteLines.${index}.turnoverGroupId`}
              render={(registered) => (
                <ReactHookFormTextField
                  {...registered}
                  fullWidth
                  select
                  variant="filled"
                  label={<FormattedMessage id="label.turnoverGroup" />}
                  InputLabelProps={{ shrink: true }}
                  className="Bas-Select-WithLabel"
                >
                  <MenuItem value="">
                    <FormattedMessage id="label.none" />
                  </MenuItem>
                  {turnoverGroups.map((turnoverGroup) => (
                    <MenuItem
                      key={turnoverGroup.turnoverGroupId}
                      value={turnoverGroup.turnoverGroupId}
                    >
                      {turnoverGroup.name}
                    </MenuItem>
                  ))}
                </ReactHookFormTextField>
              )}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <>
      <TableRow
        {...args}
        className={clsx(className, 'Bas-QuoteMomentLineForm-FirstRow')}
      >
        <TableCell>{moveActions}</TableCell>
        <TableCell>
          <Controller
            name={`quote.quoteLines.${index}.description`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                multiline
                variant="filled"
              />
            )}
          />
        </TableCell>
        <TableCell width="61px">
          <Controller
            name={`quote.quoteLines.${index}.quantity`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                variant="filled"
                InputProps={{
                  inputComponent: TextFieldNumberFormat,
                  inputProps: {
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    decimalScale: 0,
                  },
                }}
              />
            )}
          />
        </TableCell>
        <TableCell width="104px">
          <Controller
            name={`quote.quoteLines.${index}.linePrice.amount`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                variant="filled"
                InputProps={{
                  inputComponent: CurrencyTextFieldNumberFormat,
                }}
              />
            )}
          />
        </TableCell>
        <TableCell width="104px">
          <Controller
            name={`quote.quoteLines.${index}.vatCodeId`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                variant="filled"
                select
              >
                {vatCodes
                  .filter((vatCode) => !!vatCode.percentage)
                  .map((vatCode) => (
                    <MenuItem key={vatCode.vatCodeId} value={vatCode.vatCodeId}>
                      {`${vatCode.percentage?.percentage}%`}
                    </MenuItem>
                  ))}
              </ReactHookFormTextField>
            )}
          />
        </TableCell>
        <TableCell width="77px">{actions}</TableCell>
      </TableRow>
      <TableRow className={clsx(className, 'Bas-QuoteMomentLineForm-LastRow')}>
        <TableCell colSpan={2} />
        <TableCell colSpan={3}>
          <Controller
            name={`quote.quoteLines.${index}.turnoverGroupId`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                variant="filled"
                select
                label={<FormattedMessage id="label.turnoverGroup" />}
                InputLabelProps={{ shrink: true }}
                className="Bas-Select-WithLabel"
              >
                <MenuItem value="">
                  <FormattedMessage id="label.none" />
                </MenuItem>
                {turnoverGroups.map((turnoverGroup) => (
                  <MenuItem
                    key={turnoverGroup.turnoverGroupId}
                    value={turnoverGroup.turnoverGroupId}
                  >
                    {turnoverGroup.name}
                  </MenuItem>
                ))}
              </ReactHookFormTextField>
            )}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

const StyledQuoteMomentLineForm = styled(QuoteMomentLineForm)(
  ({ theme }) => `

  &.Bas-QuoteMomentLineForm-LastRow td {
    border-bottom: 1px solid ${colors.lila[400]};
  }
  .MuiAutocomplete-root {
    &, .MuiFormControl-root, .MuiFilledInput-root {
      height: 100%;
    }
  }

  .MuiFilledInput-root {
    padding-left: 13px;
    padding-right: 13px;
    &, &:hover, &.Mui-focused {
      background: #F4F4F4 !important;
      border-radius: 3px;
      border: 0;

      .MuiFilledInput-input {
        text-align: left;
      }

      .MuiFilledInput-input[inputmode="numeric"] {
        text-align: center;
      }
    }
  }
`
);
export default StyledQuoteMomentLineForm;
