import { DayOffOffInputType } from '@bas/hrm-domain/input-types';
import { Employee, ListedEmployee } from '@bas/hrm-domain/models';
import { useNationalHolidaysRequest } from '@bas/hrm-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { calculateStartAndEndTimeForWorkingDay } from '@bas/shared/utils';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';
import useDebounce from 'react-use/lib/useDebounce';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);

export type UseCalculateDaysOffProps = {
  start: Date | null | undefined;
  end: Date | null | undefined;
  daysOff: DayOffOffInputType[];
  onCalculatedDaysOff: (daysOff: DayOffOffInputType[]) => void;
  employee: Employee | ListedEmployee | undefined;
};

export const useCalculateDaysOff = ({
  start,
  end,
  daysOff,
  onCalculatedDaysOff,
  employee,
}: UseCalculateDaysOffProps) => {
  const handledStartEnd = useRef<{
    start: Date | null;
    end: Date | null;
  }>({
    start: null,
    end: null,
  });

  const tenantWorkingSchedule = useTenantStore(
    (state) => state.internalTenant?.workingSchedule
  );

  const { startHolidays, endHolidays } = useMemo(
    () => ({
      startHolidays: dayjs(start).startOf('year').format('YYYY-MM-DD'),
      endHolidays: dayjs(end).endOf('year').format('YYYY-MM-DD'),
    }),
    [start, end]
  );

  const isSameDay = useMemo(
    () => !!start && !!end && dayjs(start).isSame(end, 'day'),
    [start, end]
  );

  const { data: nationalHolidaysData, isFetching: isFetchingHolidays } =
    useNationalHolidaysRequest({
      'start[after]': startHolidays,
      'end[before]': endHolidays,
      perPage: 9999,
    });

  const nationalHolidays = useMemo(
    () => nationalHolidaysData?.data?.member || [],
    [nationalHolidaysData?.data]
  );

  useDebounce(
    () => {
      if (isFetchingHolidays) {
        return;
      }

      if (!start || !end || !employee) {
        return;
      }

      const newDaysOff: DayOffOffInputType[] = [];

      if (
        (isSameDay &&
          handledStartEnd.current.start &&
          dayjs(handledStartEnd.current.start).isSame(start, 'day') &&
          handledStartEnd.current.end &&
          dayjs(handledStartEnd.current.end).isSame(end, 'day')) ||
        (handledStartEnd.current.start &&
          dayjs(handledStartEnd.current.start).isSame(start, 'minute') &&
          handledStartEnd.current.end &&
          dayjs(handledStartEnd.current.end).isSame(end, 'minute'))
      ) {
        return;
      }

      for (
        let current = dayjs(start);
        current.isSameOrBefore(end, 'day');
        current = current.add(1, 'day')
      ) {
        const foundHoliday = nationalHolidays.find((holiday) =>
          current.isBetween(holiday.start, holiday.end, 'day', '[]')
        );

        if (!foundHoliday) {
          const { start: startOfDay, end: endOfDay } =
            calculateStartAndEndTimeForWorkingDay({
              date: current,
              availability: employee.availability,
              tenantWorkingSchedule,
              workingHours: employee.workingHours,
            });

          const existingDayOff = daysOff.find((dayOff) =>
            dayjs(dayOff.start).isSame(current, 'day')
          );

          if (existingDayOff) {
            newDaysOff.push(existingDayOff);
          } else if (!dayjs(startOfDay).isSame(endOfDay, 'minute')) {
            newDaysOff.push({
              start: dayjs(startOfDay).toDate(),
              end: dayjs(endOfDay).toDate(),
              hours: dayjs(endOfDay).diff(startOfDay, 'hour', true),
            });
          }
        }
      }

      if (!isEqual(newDaysOff, daysOff)) {
        onCalculatedDaysOff(newDaysOff);
      }

      handledStartEnd.current = {
        start,
        end,
      };
    },
    300,
    [
      isFetchingHolidays,
      nationalHolidays,
      isSameDay,
      start,
      end,
      employee,
      tenantWorkingSchedule,
      onCalculatedDaysOff,
      daysOff,
      handledStartEnd.current.start,
      handledStartEnd.current.end,
    ]
  );
};
