import { usePackagesRequest } from '@bas/project-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { useIdentitiesRequest } from '@bas/tenant-domain/requests';
import {
  IdentityIndicator,
  ReactHookFormButtonGroupField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { PricingType, ProjectType } from '@bas/value-objects';
import { Grid, MenuItem } from '@mui/material';
import { ReactElement, useEffect, useMemo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const ConnectedWebsiteForm = ({ prefix }: { prefix: string }): ReactElement => {
  const tenantState = useTenantStore((state) => state.tenant);
  const { data: identitiesData, isInitialLoading: isLoadingIdentities } =
    useIdentitiesRequest({}, { enabled: tenantState?.useIdentities || false });
  const projectType = ProjectType.MOVING_JOB;
  const { setValue } = useFormContext();

  const pricingType = useWatch({
    name: `${prefix}.pricingType`,
  });
  const identityId = useWatch({
    name: `${prefix}.identityId`,
  });
  const packageId = useWatch({
    name: `${prefix}.packageId`,
  });

  const { data: packagesData, isPending: isLoadingPackages } =
    usePackagesRequest({ perPage: 999 });

  const packages = useMemo(
    () =>
      (packagesData?.data.member || []).filter(
        (p) =>
          p.pricingType === pricingType &&
          p.projectType === projectType &&
          (!tenantState?.useIdentities || p.identityId === identityId)
      ),
    [
      identityId,
      packagesData?.data,
      pricingType,
      projectType,
      tenantState?.useIdentities,
    ]
  );

  useEffect(() => {
    if (
      packageId &&
      (packagesData?.data?.member?.length || 0) > 0 &&
      !packages.find((p) => p.packageId === packageId)
    ) {
      setValue(`${prefix}.packageId`, '');
    }
  }, [packages, packageId, setValue, packagesData?.data, prefix]);

  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12}>
        <Controller
          name={`${prefix}.name`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.name" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${prefix}.websiteCode`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.websiteCode" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${prefix}.pricingType`}
          render={(registered) => (
            <ReactHookFormButtonGroupField
              {...registered}
              label={<FormattedMessage id="label.pricingType" />}
              toggleButtonGroup={{
                size: 'small',
                exclusive: true,
              }}
              desktop
              options={Object.values(PricingType)
                .filter((type) => type !== '')
                .map((option) => ({
                  value: option,
                  label: (
                    <FormattedMessage
                      id={`pricingTypes.${option || 'unknown'}`}
                    />
                  ),
                }))}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} hidden={!tenantState?.useIdentities}>
        <Controller
          name={`${prefix}.identityId`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.identityId" />}
              select
              disabled={isLoadingIdentities}
            >
              {(identitiesData?.data.member || []).map((identity) => (
                <MenuItem key={identity.identityId} value={identity.identityId}>
                  <IdentityIndicator identityId={identity.identityId} full />
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={`${prefix}.packageId`}
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.packageId" />}
              select
              disabled={
                isLoadingPackages ||
                !pricingType ||
                (tenantState?.useIdentities && !identityId)
              }
            >
              {packages.map((p) => (
                <MenuItem key={p.packageId} value={p.packageId}>
                  {p.packageName}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default ConnectedWebsiteForm;
