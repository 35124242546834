import { useRelationByRelationIdRequestQuery } from '@bas/crm-domain/requests';
import { useEmployeesRequest } from '@bas/hrm-domain/requests';
import { Icon } from '@bas/ui/web/base';
import {
  isStorageEmptiedEvent,
  isStorageMovedToNewLocationEvent,
  isStoragePositionedForPickupEvent,
  isStorageReservedForCustomerEvent,
  isStorageWasInvoicedEvent,
  isStorageWasPickedUpEvent,
  isStorageWasReturnedToWarehouseEvent,
  StorageEvent,
  StorageEventType,
} from '@bas/wms-domain/models';
import {
  useStorageLocationByStorageLocationIdRequestQuery,
  useWarehousesRequest,
} from '@bas/wms-domain/requests';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleQuestion,
  faForklift,
  faMoneyBill1Wave,
  faSeal,
  faSealExclamation,
  faShelves,
  faShelvesEmpty,
  faSquareDashedCirclePlus,
  faTruckContainer,
  faTruckContainerEmpty,
  faUserCrown,
} from '@fortawesome/pro-light-svg-icons';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useMemo } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';

export type EventsTimelineStorageEventItemProps = {
  isLast: boolean;
  event: StorageEvent;
};

const eventTypeMapping: {
  [key: string]: {
    icon: IconProp;
    color:
      | 'inherit'
      | 'grey'
      | 'primary'
      | 'secondary'
      | 'error'
      | 'info'
      | 'success'
      | 'warning';
  };
} = {
  [StorageEventType.StorageCreatedEvent]: {
    icon: faSquareDashedCirclePlus,
    color: 'info',
  },
  [StorageEventType.StorageReservedForCustomerEvent]: {
    icon: faUserCrown,
    color: 'success',
  },
  [StorageEventType.StorageEmptiedEvent]: {
    icon: faShelvesEmpty,
    color: 'warning',
  },
  [StorageEventType.StorageWasSealedEvent]: {
    icon: faSeal,
    color: 'info',
  },
  [StorageEventType.StorageSealWasRemovedEvent]: {
    icon: faSealExclamation,
    color: 'warning',
  },
  [StorageEventType.StorageWasInvoicedEvent]: {
    icon: faMoneyBill1Wave,
    color: 'info',
  },
  [StorageEventType.StoragePositionedForPickupEvent]: {
    icon: faTruckContainerEmpty,
    color: 'info',
  },
  [StorageEventType.StorageWasPickedUpEvent]: {
    icon: faTruckContainer,
    color: 'info',
  },
  [StorageEventType.StorageWasReturnedToWarehouseEvent]: {
    icon: faShelves,
    color: 'info',
  },
  [StorageEventType.StorageMovedToNewLocationEvent]: {
    icon: faForklift,
    color: 'info',
  },
};

type StorageEventDetailsProps = {
  event: StorageEvent;
};

const StorageEventDetails = ({
  event,
}: StorageEventDetailsProps): ReactElement | null => {
  const { formatMessage, formatDate } = useIntl();
  const { data: warehousesData } = useWarehousesRequest(
    {},
    {
      enabled: [StorageEventType.StoragePositionedForPickupEvent].includes(
        event.eventType
      ),
    }
  );

  const warehouses = useMemo(
    () => warehousesData?.data.member || [],
    [warehousesData]
  );

  const oldWarehouse = useMemo(() => {
    if (
      isStoragePositionedForPickupEvent(event) ||
      isStorageMovedToNewLocationEvent(event) ||
      isStorageWasPickedUpEvent(event)
    ) {
      return warehouses.find(
        ({ warehouseId }) => warehouseId === event.oldWarehouseId
      );
    }

    return null;
  }, [event, warehouses]);

  const newWarehouse = useMemo(() => {
    if (
      isStoragePositionedForPickupEvent(event) ||
      isStorageMovedToNewLocationEvent(event) ||
      isStorageWasPickedUpEvent(event)
    ) {
      return warehouses.find(
        ({ warehouseId }) => warehouseId === event.newWarehouseId
      );
    }

    if (isStorageWasReturnedToWarehouseEvent(event)) {
      return warehouses.find(
        ({ warehouseId }) => warehouseId === event.warehouseId
      );
    }

    return null;
  }, [event, warehouses]);

  const oldLocationId = useMemo(() => {
    if (
      isStoragePositionedForPickupEvent(event) ||
      isStorageMovedToNewLocationEvent(event)
    ) {
      return event.oldStorageLocationId;
    }

    return null;
  }, [event]);

  const newLocationId = useMemo(() => {
    if (isStorageMovedToNewLocationEvent(event)) {
      return event.newStorageLocationId;
    }

    if (isStorageWasReturnedToWarehouseEvent(event)) {
      return event.storageLocationId;
    }

    return null;
  }, [event]);

  const { data: oldLocationData } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: oldLocationId || '',
      },
      {
        enabled: !!oldLocationId,
      }
    );

  const { data: newLocationData } =
    useStorageLocationByStorageLocationIdRequestQuery(
      {
        storageLocationId: newLocationId || '',
      },
      {
        enabled: !!newLocationId,
      }
    );

  const oldLocation = useMemo(
    () => oldLocationData?.data,
    [oldLocationData?.data]
  );

  const newLocation = useMemo(
    () => newLocationData?.data,
    [newLocationData?.data]
  );

  const customerId = useMemo(() => {
    if (isStorageReservedForCustomerEvent(event)) {
      return event.contentOwnerId;
    }

    if (isStorageEmptiedEvent(event)) {
      return event.oldContentOwnerId;
    }

    return null;
  }, [event]);

  const { data: customerData } = useRelationByRelationIdRequestQuery(
    {
      relationId: customerId || '',
    },
    {
      enabled: !!customerId,
    }
  );

  const customer = useMemo(() => customerData?.data, [customerData?.data]);

  const unknownLabel = formatMessage({
    id: 'label.unknown',
  }).toLocaleLowerCase();
  if (isStoragePositionedForPickupEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storagePositionedForPickupFrom"
          values={{
            oldWarehouse: oldWarehouse?.name,
            oldLocation: oldLocation
              ? `${oldLocation?.name} (${oldLocation?.code})`
              : unknownLabel,
          }}
        />
      </Typography>
    );
  }

  if (isStorageMovedToNewLocationEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storageMovedToNewLocationFromTo"
          values={{
            oldWarehouse: oldWarehouse?.name,
            oldLocation: oldLocation
              ? `${oldLocation?.name} (${oldLocation?.code})`
              : unknownLabel,

            newWarehouse: newWarehouse?.name,
            newLocation: newLocation
              ? `${newLocation?.name} (${newLocation?.code})`
              : unknownLabel,
          }}
        />
      </Typography>
    );
  }

  if (isStorageWasPickedUpEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storageWasPickedUpFrom"
          values={{
            oldWarehouse: oldWarehouse?.name,
          }}
        />
      </Typography>
    );
  }

  if (isStorageWasReturnedToWarehouseEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storageWasReturnedToWarehouse"
          values={{
            newWarehouse: newWarehouse?.name,
          }}
        />
      </Typography>
    );
  }

  if (isStorageReservedForCustomerEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storageReservedForCustomer"
          values={{
            customerName: customer?.name,
          }}
        />
      </Typography>
    );
  }

  if (isStorageEmptiedEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storageEmptied"
          values={{
            customerName: customer?.name,
          }}
        />
      </Typography>
    );
  }

  if (isStorageWasInvoicedEvent(event)) {
    return (
      <Typography>
        <FormattedMessage
          id="label.storageWasInvoiced"
          values={{
            oldLastInvoicedDate: event.oldLastInvoicedDate
              ? formatDate(dayjs(event.oldLastInvoicedDate).toDate())
              : formatMessage({ id: 'label.never' }),
            newLastInvoicedDate: formatDate(
              dayjs(event.newLastInvoicedDate).toDate()
            ),
          }}
        />
      </Typography>
    );
  }

  return null;
};

const EventsTimelineStorageEventItem = ({
  event,
  isLast,
}: EventsTimelineStorageEventItemProps): ReactElement => {
  const { formatMessage } = useIntl();
  const { data: employeesData } = useEmployeesRequest({
    perPage: 9999,
    activeEmploymentOnly: false,
    backofficeOnly: false,
  });

  const employee = useMemo(
    () =>
      employeesData?.data.member.find(
        ({ employeeId }) => employeeId === event.employeeId
      ),
    [employeesData, event.employeeId]
  );

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={eventTypeMapping[event.eventType]?.color}>
          <Icon
            icon={eventTypeMapping[event.eventType]?.icon || faCircleQuestion}
          />
        </TimelineDot>
        {isLast && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Typography variant="subtitle1">
          <FormattedMessage id={`eventsTimeline.storage.${event.eventType}`} />
        </Typography>
        <StorageEventDetails event={event} />
        <Typography variant="subtitle2">
          <FormattedDate
            value={event.eventDate}
            dateStyle="medium"
            timeStyle="short"
          />
          {` - ${
            employee?.personName?.fullName ||
            formatMessage({ id: 'label.systemUser' })
          }`}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default EventsTimelineStorageEventItem;
