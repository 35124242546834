import { Integration, isWebsiteSettings } from '@bas/integration-domain/models';
import { usePackagesRequest } from '@bas/project-domain/requests';
import { useTenantStore } from '@bas/shared/state';
import { TextField, Tooltip } from '@bas/ui/web/atoms';
import { Icon } from '@bas/ui/web/base';
import {
  ContentSection,
  ContentSectionAddAction,
  ContentSectionCancelAction,
  ContentSectionEditAction,
  ContentSectionSaveAction,
  IdentityIndicator,
} from '@bas/ui/web/molecules';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { Box, Grid, Skeleton, styled, Typography } from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

export type WebsiteSettingsContentSectionProps = {
  className?: string;
  integration: Integration;
  onAdd: () => void;
  onEdit: () => void;
  onCancel?: () => void;
  children?: ReactNode;
};

const WebsiteSettingsContentSection = ({
  className,
  integration: { providerSettings, tenantId, integrationId },
  onAdd,
  onEdit,
  onCancel,
  children,
}: WebsiteSettingsContentSectionProps): ReactElement => {
  const tenantState = useTenantStore((state) => state.internalTenant);
  const { data: packagesData, isPending: isLoadingPackages } =
    usePackagesRequest({ perPage: 999 });
  const apiUrl = `${
    import.meta.env.VITE_API_URL
  }api/public/${tenantId}/integrations/${integrationId}/new-moving-job-lead`;

  if (!isWebsiteSettings(providerSettings)) {
    return <span />;
  }

  if (providerSettings.websites.length === 0) {
    return (
      <ContentSection
        editing={!!children}
        actions={
          children && onCancel
            ? [
                <ContentSectionSaveAction />,
                <ContentSectionCancelAction onClick={onCancel} />,
              ]
            : [<ContentSectionAddAction onClick={onAdd} />]
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.websites" />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormattedMessage id="label.noConnectedWebsites" />
          </Grid>
          <Grid item xs={12} pt={4} hidden={!children}>
            {children}
          </Grid>
        </Grid>
      </ContentSection>
    );
  }

  return (
    <ContentSection
      className={className}
      editing={!!onCancel}
      actions={
        onCancel
          ? [
              <ContentSectionSaveAction />,
              <ContentSectionCancelAction onClick={onCancel} />,
            ]
          : [
              <ContentSectionAddAction onClick={onAdd} />,
              <ContentSectionEditAction onClick={onEdit} />,
            ]
      }
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.websites" />
          </Typography>
        </Grid>
        <Grid container spacing={4}>
          {providerSettings.websites.map((connectedWebsite) => (
            <Grid
              item
              xs={12}
              container
              columns={11}
              key={connectedWebsite.websiteCode}
            >
              <Grid item xs={5}>
                <Typography>
                  <FormattedMessage id="label.name" />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{connectedWebsite.name}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  <FormattedMessage id="label.websiteCode" />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{connectedWebsite.websiteCode}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  <FormattedMessage id="label.packageId" />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {isLoadingPackages && <Skeleton variant="text" width="100" />}
                  {
                    packagesData?.data?.member.find(
                      ({ packageId }) =>
                        packageId === connectedWebsite.packageId
                    )?.packageName
                  }
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography>
                  <FormattedMessage id="label.pricingType" />
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  <FormattedMessage
                    id={`pricingTypes.${connectedWebsite.pricingType}`}
                  />
                </Typography>
              </Grid>
              {tenantState?.useIdentities && (
                <>
                  <Grid item xs={5}>
                    <Typography>
                      <FormattedMessage id="label.identityId" />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <IdentityIndicator
                        identityId={connectedWebsite.identityId}
                        full
                      />
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
        <Grid item xs={12} container spacing={1} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.apiUrl" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {!apiUrl && <Skeleton variant="text" />}
              {apiUrl && (
                <TextField
                  id="apiUrl"
                  value={apiUrl}
                  variant="outlined"
                  fullWidth
                  sx={{ padding: 0 }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="label.copy" />}>
              <Box>
                <Icon
                  sx={{ cursor: 'pointer' }}
                  icon={faCopy}
                  onClick={() => {
                    const copyText = document.getElementById('apiUrl');
                    if (copyText instanceof HTMLInputElement) {
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      navigator.clipboard.writeText(copyText.value);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.tenantId" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {!tenantId && <Skeleton variant="text" />}
              {tenantId && (
                <TextField
                  id="tenantId"
                  value={tenantId}
                  variant="outlined"
                  fullWidth
                  sx={{ padding: 0 }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="label.copy" />}>
              <Box>
                <Icon
                  sx={{ cursor: 'pointer' }}
                  icon={faCopy}
                  onClick={() => {
                    const copyText = document.getElementById('tenantId');
                    if (copyText instanceof HTMLInputElement) {
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      navigator.clipboard.writeText(copyText.value);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              <FormattedMessage id="label.integrationId" />
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography>
              {!integrationId && <Skeleton variant="text" />}
              {integrationId && (
                <TextField
                  id="integrationId"
                  value={integrationId}
                  variant="outlined"
                  fullWidth
                  sx={{ padding: 0 }}
                />
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={<FormattedMessage id="label.copy" />}>
              <Box>
                <Icon
                  sx={{ cursor: 'pointer' }}
                  icon={faCopy}
                  onClick={() => {
                    const copyText = document.getElementById('integrationId');
                    if (copyText instanceof HTMLInputElement) {
                      copyText.select();
                      copyText.setSelectionRange(0, 99999);
                      navigator.clipboard.writeText(copyText.value);
                    }
                  }}
                />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </ContentSection>
  );
};

const StyledWebsiteSettingsContentSection = styled(
  WebsiteSettingsContentSection
)(
  () => `
  .MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root {
    width: 100%;
    padding: 0;
  }
`
);
export default StyledWebsiteSettingsContentSection;
