import {
  AccountingIntegrationSettings,
  CostCenterMappingType,
  GeneralLedgerMappingType,
} from '@bas/integration-domain/models';
import {
  useCostCenterBasOptions,
  useGeneralLedgerBasOptions,
  useVatCodesBasOptions,
} from '@bas/integration-domain/web/hooks';
import { useGetTranslatedName } from '@bas/shared/hooks';
import { useTenantStore } from '@bas/shared/state';
import { IdentityType } from '@bas/tenant-domain/models';
import {
  useCostTypesRequest,
  useIdentitiesRequest,
} from '@bas/tenant-domain/requests';
import { IdentityIndicator } from '@bas/ui/web/molecules';
import { MappingTableExternalOption } from '@bas/value-objects';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { MappingTable } from '../MappingTable';

export type AccountingIntegrationSettingsBlockProps = {
  providerSettings: AccountingIntegrationSettings;
  externalGeneralLedgers: MappingTableExternalOption[];
  externalCostCenters: MappingTableExternalOption[];
  externalVatCodes: MappingTableExternalOption[];
  externalAdministrations?: MappingTableExternalOption[];
};

const AccountingIntegrationSettingsBlock = ({
  providerSettings,
  externalGeneralLedgers,
  externalCostCenters,
  externalVatCodes,
  externalAdministrations = [],
}: AccountingIntegrationSettingsBlockProps): ReactElement => {
  const basGeneralLedgers = useGeneralLedgerBasOptions(
    providerSettings.generalLedgerMappingType
  );
  const basCostCenters = useCostCenterBasOptions(
    providerSettings.costCenterMappingType
  );
  const basVatCodes = useVatCodesBasOptions();

  const useIdentities = useTenantStore(
    (state) => state.tenant?.useIdentities || false
  );

  const { data: identitiesData } = useIdentitiesRequest(
    {},
    { enabled: useIdentities || false }
  );

  const identitiesToShow = useMemo(
    () =>
      (identitiesData?.data.member || []).filter(
        ({ identityType }) => identityType !== IdentityType.TRADE_NAME
      ),
    [identitiesData]
  );

  const identityOptions = useMemo(
    () =>
      identitiesToShow.map(({ identityId, companyInformation }) => ({
        basId: identityId,
        label: <IdentityIndicator identityId={identityId} full />,
      })),
    [identitiesToShow]
  );

  const { data: costTypesData } = useCostTypesRequest(
    {
      perPage: 99999,
    },
    {
      enabled: providerSettings.enablePurchaseOrdersPerProjectByCostCenter,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const getTranslatedName = useGetTranslatedName();
  const basCostTypes = useMemo(
    () =>
      (costTypesData?.data?.member || []).map(
        ({ costTypeId, costTypeName, translatedNames }) => ({
          basId: costTypeId,
          label: getTranslatedName(translatedNames, costTypeName),
        })
      ),
    [costTypesData?.data, getTranslatedName]
  );

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id="label.startDate" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {providerSettings.startDate ? (
            <FormattedDate
              value={providerSettings.startDate}
              dateStyle="long"
            />
          ) : (
            <FormattedMessage id="label.unknown" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id="label.leadingNumber" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {providerSettings.leadingNumber || (
            <FormattedMessage id="label.none" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id="label.generalLedgerMappingType" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {providerSettings.generalLedgerMappingType ? (
            <FormattedMessage
              id={`generalLedgerMappingTypes.${providerSettings.generalLedgerMappingType}`}
            />
          ) : (
            <FormattedMessage id="label.unknown" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id="label.costCenterMappingType" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {providerSettings.costCenterMappingType ? (
            <FormattedMessage
              id={`costCenterMappingTypes.${providerSettings.costCenterMappingType}`}
            />
          ) : (
            <FormattedMessage id="label.unknown" />
          )}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id="label.defaultGeneralLedger" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {externalGeneralLedgers.length > 0
            ? externalGeneralLedgers.find(
                ({ externalId }) =>
                  externalId === providerSettings.defaultGeneralLedger
              )?.label || <FormattedMessage id="label.none" />
            : providerSettings.defaultGeneralLedger || (
                <FormattedMessage id="label.none" />
              )}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <FormattedMessage id="label.discountGeneralLedger" />
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          {externalGeneralLedgers.length > 0
            ? externalGeneralLedgers.find(
                ({ externalId }) =>
                  externalId === providerSettings.discountGeneralLedger
              )?.label || <FormattedMessage id="label.none" />
            : providerSettings.discountGeneralLedger || (
                <FormattedMessage id="label.none" />
              )}
        </Typography>
      </Grid>
      <Grid item xs={12} container spacing={4} mt={4}>
        {useIdentities && (externalAdministrations?.length || 0) > 0 && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className="Bas-ContentSection-DontChangeColor"
              pb={2}
            >
              <FormattedMessage id="label.administration" />
            </Typography>
            <MappingTable
              mappings={providerSettings.administrationMapping || []}
              externalOptions={externalAdministrations}
              basOptions={identityOptions}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="Bas-ContentSection-DontChangeColor"
            pb={2}
          >
            <FormattedMessage id="label.generalLedgers" />
          </Typography>
          <MappingTable
            mappings={providerSettings.generalLedgerMapping}
            externalOptions={externalGeneralLedgers}
            basOptions={basGeneralLedgers}
            vatMapping={
              providerSettings.generalLedgerMappingType ===
              GeneralLedgerMappingType.PER_VAT_CODE
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={
            providerSettings.costCenterMappingType ===
            CostCenterMappingType.NONE
          }
        >
          <Typography
            variant="subtitle1"
            className="Bas-ContentSection-DontChangeColor"
            pb={2}
          >
            <FormattedMessage id="label.costCenters" />
          </Typography>
          <MappingTable
            mappings={providerSettings.costCenterMapping}
            externalOptions={externalCostCenters}
            basOptions={basCostCenters}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle1"
            className="Bas-ContentSection-DontChangeColor"
            pb={2}
          >
            <FormattedMessage id="label.vatCodes" />
          </Typography>
          <MappingTable
            mappings={providerSettings.vatMapping}
            externalOptions={externalVatCodes}
            basOptions={basVatCodes}
            vatMapping
          />
        </Grid>
        {providerSettings.enablePurchaseOrdersPerProjectByCostCenter && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle1"
              className="Bas-ContentSection-DontChangeColor"
              pb={2}
            >
              <FormattedMessage id="label.purchaseCosts" />
            </Typography>
            <MappingTable
              mappings={providerSettings.purchaseCostMapping || []}
              externalOptions={externalGeneralLedgers}
              basOptions={basCostTypes}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default AccountingIntegrationSettingsBlock;
