import { Integration, isMollieSettings } from '@bas/integration-domain/models';
import { useMollieProfilesByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  ContentSection,
  ContentSectionEditAction,
} from '@bas/ui/web/molecules';
import { Grid, Typography } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

export type MollieSettingsContentSectionProps = {
  integration: Integration;
  onEdit: () => void;
};

const MollieSettingsContentSection = ({
  integration: { integrationId, providerSettings },
  onEdit,
}: MollieSettingsContentSectionProps): ReactElement => {
  const { data: profilesData } = useMollieProfilesByIntegrationIdRequest({
    integrationId,
  });

  const profile = useMemo(() => {
    if (!isMollieSettings(providerSettings)) {
      return undefined;
    }

    if (!providerSettings.profileId) {
      return undefined;
    }

    return (profilesData?.data?.member || []).find(
      ({ profileId }) => profileId === providerSettings?.profileId
    );
  }, [providerSettings, profilesData?.data]);

  return (
    <ContentSection actions={[<ContentSectionEditAction onClick={onEdit} />]}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <FormattedMessage id="label.settings" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.profileId" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isMollieSettings(providerSettings) && profile ? (
              profile.name
            ) : (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <FormattedMessage id="label.testMode" />
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {isMollieSettings(providerSettings) && (
              <FormattedMessage
                id={`label.${
                  providerSettings.testMode ? 'enabled' : 'disabled'
                }`}
              />
            )}
            {!isMollieSettings(providerSettings) && (
              <FormattedMessage id="label.unknown" />
            )}
          </Typography>
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default MollieSettingsContentSection;
