import { CreatePackageInputType } from '@bas/tenant-domain/input-types';
import { useDocumentTemplatesRequest } from '@bas/tenant-domain/requests';
import { TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  ReactHookFormButtonGroupField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { DocumentType, ProjectType } from '@bas/value-objects';
import { Grid, MenuItem } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { QuoteMomentLinesForm } from '../QuoteMomentLinesForm';

export type QuoteMomentFormProps = {
  useIdentities: boolean;
  projectType?: ProjectType;
  forceQuoteMoment?: boolean;
};

const QuoteMomentForm = ({
  useIdentities,
  projectType,
  forceQuoteMoment,
}: QuoteMomentFormProps): ReactElement => {
  const identityId = useWatch<CreatePackageInputType, 'identityId'>({
    name: 'identityId',
  });

  const useQuoteMoment = useWatch({
    name: `quote.enabled`,
  });

  const templateId = useWatch({
    name: `quote.documentTemplateId`,
  });

  const { data: templatesData, isPending: isLoadingTemplates } =
    useDocumentTemplatesRequest({
      perPage: 99999,
      'documentType.value': DocumentType.QUOTE,
    });

  const templates = useMemo(
    () =>
      (templatesData?.data.member || []).filter(
        (p) => !useIdentities || p.identityId === identityId
      ),
    [identityId, templatesData?.data, useIdentities]
  );

  const currentTemplate = useMemo(
    () => templates.find((t) => t.documentTemplateId === templateId),
    [templateId, templates]
  );

  const freeTextFields = useMemo(
    () =>
      (currentTemplate?.translations || [])
        .map((translation) => translation.freeTextFields)
        .flat()
        .filter((value, index, array) => array.indexOf(value) === index),
    [currentTemplate?.translations]
  );

  return (
    <Grid container rowSpacing={3} columnSpacing={3}>
      {!forceQuoteMoment && (
        <Grid item xs={12}>
          <Controller
            name="quote.enabled"
            render={(registered) => (
              <ReactHookFormButtonGroupField
                {...registered}
                label={<FormattedMessage id="label.useQuoteMoment" />}
                toggleButtonGroup={{
                  size: 'small',
                  exclusive: true,
                }}
                desktop
                options={[
                  {
                    value: true,
                    label: <FormattedMessage id="label.yes" />,
                  },
                  {
                    value: false,
                    label: <FormattedMessage id="label.no" />,
                  },
                ]}
              />
            )}
          />
        </Grid>
      )}
      <Grid item xs={12} hidden={!useQuoteMoment && !forceQuoteMoment}>
        <Controller
          name="quote.includingVat"
          render={(registered) => (
            <ReactHookFormButtonGroupField
              {...registered}
              label={<FormattedMessage id="label.includingOrExcludingVat" />}
              toggleButtonGroup={{
                size: 'small',
                exclusive: true,
              }}
              desktop
              options={[
                {
                  label: <FormattedMessage id="label.includingVat" />,
                  value: true,
                },
                {
                  label: <FormattedMessage id="label.excludingVat" />,
                  value: false,
                },
              ]}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} hidden={!useQuoteMoment && !forceQuoteMoment}>
        <Controller
          name="quote.documentTemplateId"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.templateId" />}
              select
              loading={isLoadingTemplates}
              disabled={isLoadingTemplates || (useIdentities && !identityId)}
            >
              {templates.map((t) => (
                <MenuItem
                  key={t.documentTemplateId}
                  value={t.documentTemplateId}
                >
                  {t.name}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        rowSpacing={2}
        columnSpacing={3}
        hidden={!useQuoteMoment && !forceQuoteMoment}
      >
        {freeTextFields &&
          freeTextFields.map((freeTextField) => (
            <Grid item xs={6} key={freeTextField}>
              <Controller
                name={`quote.freeText.${freeTextField}`}
                render={(registered) => (
                  <ReactHookFormTextField
                    {...registered}
                    fullWidth
                    label={
                      !Number.isNaN(Number.parseInt(freeTextField, 10) + 1) ? (
                        <FormattedMessage
                          id="label.freeTextTitle"
                          values={{
                            index: Number.parseInt(freeTextField, 10) + 1,
                          }}
                        />
                      ) : (
                        freeTextField
                      )
                    }
                    multiline
                  />
                )}
              />
            </Grid>
          ))}
      </Grid>
      <Grid item xs={6} hidden={!useQuoteMoment && !forceQuoteMoment}>
        <Controller
          name="quote.discount.description"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.discountDescription" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6} hidden={!useQuoteMoment && !forceQuoteMoment}>
        <Controller
          name="quote.discount.percentage"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.percentage" />}
              InputProps={{
                inputComponent: TextFieldNumberFormat,
                inputProps: {
                  pattern: '[0-9]*',
                  inputMode: 'numeric',
                  decimalScale: 2,
                  suffix: '%',
                },
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} hidden={!useQuoteMoment && !forceQuoteMoment}>
        <QuoteMomentLinesForm projectType={projectType} />
      </Grid>
    </Grid>
  );
};

export default QuoteMomentForm;
