import { useMollieProfilesByIntegrationIdRequest } from '@bas/integration-domain/requests';
import {
  ReactHookFormCheckbox,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { Uuid } from '@bas/value-objects';
import { Grid, MenuItem } from '@mui/material';
import { ReactElement } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

export type MollieSettingsFormProps = {
  integrationId: Uuid;
};

const MollieSettingsForm = ({
  integrationId,
}: MollieSettingsFormProps): ReactElement => {
  const { data: profilesData } = useMollieProfilesByIntegrationIdRequest({
    integrationId,
  });
  return (
    <Grid container columnSpacing={3} rowSpacing={3}>
      <Grid item xs={12}>
        <Controller
          name="testMode"
          render={(registered) => (
            <ReactHookFormCheckbox
              {...registered}
              label={<FormattedMessage id="label.testMode" />}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="profileId"
          render={(registered) => (
            <ReactHookFormTextField
              {...registered}
              fullWidth
              label={<FormattedMessage id="label.profileId" />}
              select
            >
              {(profilesData?.data?.member || []).map((profile) => (
                <MenuItem key={profile.profileId} value={profile.profileId}>
                  {profile.name}
                </MenuItem>
              ))}
            </ReactHookFormTextField>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default MollieSettingsForm;
