import { useAuthStore, useTenantStore } from '@bas/shared/state';
import { setDefaultCountry } from '@bas/shared/utils';
import {
  useInternalTenantByHostnameRequest,
  useTenantByHostnameRequest,
} from '@bas/tenant-domain/requests';
import dayjs from 'dayjs';
import { useEffect } from 'react';

export const useRetrieveAndSetTenantStores = (
  domainName: string,
  isWeb = true
) => {
  const token = useAuthStore(state => state.token);
  const expiresAt = useAuthStore(state => state.expiresAt);
  const setTenant = useTenantStore(state => state.setTenant);
  const setInternalTenant = useTenantStore(state => state.setInternalTenant);

  const { data: tenantData } = useInternalTenantByHostnameRequest(
    {
      hostname: domainName,
      token: token || '',
    },
    {
      enabled: !!token && dayjs(expiresAt).isAfter(dayjs()) && !!domainName,
    }
  );

  const { data: publicTenantData } = useTenantByHostnameRequest(
    {
      hostname: domainName,
    },
    {
      enabled: !!domainName,
    }
  );

  useEffect(() => {
    if (!publicTenantData?.data?.tenantId) {
      return;
    }

    setTenant(publicTenantData.data);
    setDefaultCountry(publicTenantData.data.address.country);
    if (isWeb && !document.title.includes(publicTenantData.data.name)) {
      document.title = `${publicTenantData.data.name} - ${document.title}`;
    }
  }, [expiresAt, token, setTenant, publicTenantData?.data, isWeb]);

  useEffect(() => {
    if (!tenantData?.data?.tenantId) {
      return;
    }

    setInternalTenant(tenantData.data);
  }, [expiresAt, token, setInternalTenant, tenantData?.data]);
};
