import { useTurnoverGroupsRequest } from '@bas/financial-domain/requests';
import { BillingMomentLineType, BillingMomentType } from '@bas/shared/models';
import { CreatePackageInputType } from '@bas/tenant-domain/input-types';
import { useDocumentTemplatesRequest } from '@bas/tenant-domain/requests';
import { TextFieldNumberFormat } from '@bas/ui/web/atoms';
import {
  ReactHookFormButtonGroupField,
  ReactHookFormTextField,
} from '@bas/ui/web/molecules';
import { DocumentType } from '@bas/value-objects';
import { Grid, MenuItem, Typography } from '@mui/material';
import { memo, ReactElement, useMemo } from 'react';
import isEqual from 'react-fast-compare';
import { Controller, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { BillingMomentLinesForm } from '../BillingMomentLinesForm';

export type BillingMomentFormProps = {
  prefix: string;
  useIdentities: boolean;
  isEventBillingMoment?: boolean;
  forceBillingMoment?: boolean;
  hideTask?: boolean;
};

const BillingMomentForm = ({
  prefix,
  useIdentities,
  isEventBillingMoment,
  forceBillingMoment,
  hideTask,
}: BillingMomentFormProps): ReactElement => {
  const identityId = useWatch<CreatePackageInputType, 'identityId'>({
    name: 'identityId',
  });

  const billingMomentType = useWatch({
    name: `${prefix}.billingMomentType`,
  });

  const lineType = useWatch({
    name: `${prefix}.lineType`,
  });

  const useBillingMoment = useWatch({
    name: `${prefix}.useBillingMoment`,
  });

  const templateId = useWatch({
    name: `${prefix}.documentTemplateId`,
  });

  const { data: templatesData, isPending: isLoadingTemplates } =
    useDocumentTemplatesRequest({
      perPage: 99999,
      'documentType.value': DocumentType.INVOICE,
    });

  const { data: turnoverGroupsData } = useTurnoverGroupsRequest(
    {
      perPage: 9999,
    },
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );

  const templates = useMemo(
    () =>
      (templatesData?.data.member || []).filter(
        (p) => !useIdentities || p.identityId === identityId
      ),
    [identityId, templatesData?.data, useIdentities]
  );

  const currentTemplate = useMemo(
    () => templates.find((t) => t.documentTemplateId === templateId),
    [templateId, templates]
  );

  const freeTextFields = useMemo(
    () =>
      (currentTemplate?.translations || [])
        .map((translation) => translation.freeTextFields)
        .flat()
        .filter((value, index, array) => array.indexOf(value) === index),
    [currentTemplate?.translations]
  );

  const turnoverGroups = useMemo(
    () => turnoverGroupsData?.data.member || [],
    [turnoverGroupsData?.data]
  );

  return useMemo(
    () => (
      <Grid container rowSpacing={3} columnSpacing={3}>
        {!forceBillingMoment && (
          <Grid item xs={12}>
            <Controller
              name={`${prefix}.useBillingMoment`}
              render={(registered) => (
                <ReactHookFormButtonGroupField
                  {...registered}
                  label={<FormattedMessage id="label.useBillingMoment" />}
                  toggleButtonGroup={{
                    size: 'small',
                    exclusive: true,
                  }}
                  desktop
                  options={[
                    {
                      value: true,
                      label: <FormattedMessage id="label.yes" />,
                    },
                    {
                      value: false,
                      label: <FormattedMessage id="label.no" />,
                    },
                  ]}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} hidden={!useBillingMoment && !forceBillingMoment}>
          <Controller
            name={`${prefix}.includingVat`}
            render={(registered) => (
              <ReactHookFormButtonGroupField
                {...registered}
                label={<FormattedMessage id="label.includingOrExcludingVat" />}
                toggleButtonGroup={{
                  size: 'small',
                  exclusive: true,
                }}
                desktop
                options={[
                  {
                    label: <FormattedMessage id="label.includingVat" />,
                    value: true,
                  },
                  {
                    label: <FormattedMessage id="label.excludingVat" />,
                    value: false,
                  },
                ]}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={
            (!useBillingMoment && !forceBillingMoment) || !isEventBillingMoment
          }
        >
          <Controller
            name={`${prefix}.payAfterFinalizingEvent`}
            render={(registered) => (
              <ReactHookFormButtonGroupField
                {...registered}
                label={<FormattedMessage id="label.payAfterFinalizingEvent" />}
                toggleButtonGroup={{
                  size: 'small',
                  exclusive: true,
                }}
                desktop
                options={[
                  {
                    value: true,
                    label: <FormattedMessage id="label.yes" />,
                  },
                  {
                    value: false,
                    label: <FormattedMessage id="label.no" />,
                  },
                ]}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} hidden={!useBillingMoment && !forceBillingMoment}>
          <Controller
            name={`${prefix}.documentTemplateId`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.templateId" />}
                select
                loading={isLoadingTemplates}
                disabled={isLoadingTemplates || (useIdentities && !identityId)}
              >
                {templates.map((t) => (
                  <MenuItem
                    key={t.documentTemplateId}
                    value={t.documentTemplateId}
                  >
                    {t.name}
                  </MenuItem>
                ))}
              </ReactHookFormTextField>
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          rowSpacing={2}
          columnSpacing={3}
          hidden={!useBillingMoment && !forceBillingMoment}
        >
          {freeTextFields &&
            freeTextFields.map((freeTextField) => (
              <Grid item xs={6} key={freeTextField}>
                <Controller
                  name={`${prefix}.freeText.${freeTextField}`}
                  render={(registered) => (
                    <ReactHookFormTextField
                      {...registered}
                      fullWidth
                      label={
                        !Number.isNaN(
                          Number.parseInt(freeTextField, 10) + 1
                        ) ? (
                          <FormattedMessage
                            id="label.freeTextTitle"
                            values={{
                              index: Number.parseInt(freeTextField, 10) + 1,
                            }}
                          />
                        ) : (
                          freeTextField
                        )
                      }
                      multiline
                    />
                  )}
                />
              </Grid>
            ))}
        </Grid>
        <Grid item xs={6} hidden={!useBillingMoment && !forceBillingMoment}>
          <Controller
            name={`${prefix}.discount.description`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.discountDescription" />}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} hidden={!useBillingMoment && !forceBillingMoment}>
          <Controller
            name={`${prefix}.discount.percentage`}
            render={(registered) => (
              <ReactHookFormTextField
                {...registered}
                fullWidth
                label={<FormattedMessage id="label.percentage" />}
                InputProps={{
                  inputComponent: TextFieldNumberFormat,
                  inputProps: {
                    pattern: '[0-9]*',
                    inputMode: 'numeric',
                    decimalScale: 2,
                    suffix: '%',
                  },
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} hidden={!useBillingMoment || hideTask}>
          <Controller
            name={`${prefix}.period`}
            render={(registered) => (
              <ReactHookFormButtonGroupField
                {...registered}
                label={<FormattedMessage id="label.createTaskAfter" />}
                toggleButtonGroup={{
                  size: 'small',
                  exclusive: true,
                }}
                desktop
                options={['direct', 'hour', 'day', 'week', 'month'].map(
                  (period) => ({
                    value: period,
                    label: (
                      <FormattedMessage id={`billingMomentPeriods.${period}`} />
                    ),
                  })
                )}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={
            !useBillingMoment ||
            billingMomentType ===
              BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED
          }
        >
          <Controller
            name={`${prefix}.lineType`}
            render={(registered) => (
              <ReactHookFormButtonGroupField
                {...registered}
                label={<FormattedMessage id="label.lineType" />}
                toggleButtonGroup={{
                  size: 'small',
                  exclusive: true,
                }}
                desktop
                options={Object.values(BillingMomentLineType)
                  .filter((option) => !!option)
                  .map((option) => ({
                    value: option,
                    label: (
                      <FormattedMessage
                        id={`billingMomentLineTypes.${option}`}
                      />
                    ),
                  }))}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          hidden={
            billingMomentType !==
            BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED
          }
        >
          <Typography variant="subtitle1">
            <FormattedMessage id="label.extraLines" />
          </Typography>
          <Typography>
            <FormattedMessage id="label.billingMomentWhenProjectIsFinishedExplained" />
          </Typography>
        </Grid>
        <Grid item xs={12} hidden={!useBillingMoment && !forceBillingMoment}>
          <BillingMomentLinesForm
            prefix={prefix}
            lineType={
              billingMomentType ===
              BillingMomentType.BILLING_MOMENT_WHEN_PROJECT_IS_FINISHED
                ? BillingMomentLineType.FIXED_AMOUNT
                : lineType
            }
            turnoverGroups={turnoverGroups}
          />
        </Grid>
      </Grid>
    ),
    [
      billingMomentType,
      forceBillingMoment,
      freeTextFields,
      hideTask,
      identityId,
      isEventBillingMoment,
      isLoadingTemplates,
      lineType,
      prefix,
      templates,
      turnoverGroups,
      useBillingMoment,
      useIdentities,
    ]
  );
};

const MemoBillingMomentForm = memo(BillingMomentForm, (prev, next) =>
  isEqual(prev, next)
);

export default MemoBillingMomentForm;
